import React, { useState, useContext } from 'react';
import * as Styled from './style';
import { IconButton, Drawer, Stack, Typography, Divider } from '@mui/material';
import { MdInfoOutline, MdOutlineClose } from 'react-icons/md';
import { constants } from '../../constants/Constants';
import { color } from '../../theme/Color';
import { FilterContext } from '../../components/FilterBar/FilterProvider';
import { ThemeContext } from '../../context/ThemeContext';

export default function KpiDefinitionDrawer({
  title = '',
  KpiDefinitionList,
  sectorName = '',
  ppSubHeadingNote = false,
  selectedTool = '',
}) {
  const { mode } = useContext(ThemeContext);
  const [isDrawerShown, setIsDrawerShown] = useState(false);
  const { selectedModel } = useContext(FilterContext);
  function toggleDrawer(isShown) {
    setIsDrawerShown(isShown);
  }
  const getUniqueKey = (prefix, index) => `${prefix}-${index}`;

  const getSubHeading = () => {
    if (selectedModel?.id === constants?.modals?.productPairing) {
      return constants?.productPairing?.subHeading;
    } else if (selectedModel?.id === constants?.modals?.shareWise) {
      return constants?.shareWise?.subHeading;
    } else if (selectedModel?.id === constants?.modals?.nbA) {
      return constants?.nba?.subHeading;
    } else {
      return '';
    }
  };
  const definitionList = KpiDefinitionList?.map((section, sectionIndex) => {
    return (
      <div key={getUniqueKey('section', sectionIndex)} className="kpiSection">
        <Typography className="sectionName">{section?.sectionName}</Typography>
        {section?.sectionKpiList?.map((kpi, kpiIndex) => {
          let updatedDefinition = kpi?.definition;

          // Check if {sector} is present before replacing
          if (updatedDefinition.includes('{sector}')) {
            updatedDefinition = updatedDefinition.replace(
              /{sector}/g,
              sectorName
            );
          }

          // in Automation mapping selectedTool to kpi category
          if (selectedTool !== '' && kpi.kpiNameCategory !== selectedTool) {
            return null;
          }

          return (
            <>
              <Typography className="kpiName">{kpi?.kpiName}</Typography>
              <Typography className="kpiDefinition">
                {updatedDefinition.split('\\n').map((line, index) => (
                  <React.Fragment key={getUniqueKey('line', index)}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
              {kpiIndex < section?.sectionKpiList.length - 1 && (
                <Divider orientation="horizontal" className="kpiDivider" />
              )}
            </>
          );
        })}
      </div>
    );
  });

  return (
    <span data-testid="kpi-denifition-drawer-group-container">
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          toggleDrawer(true);
        }}
        data-testid="btn-show-drawer"
        sx={{ paddingTop: 0, paddingBottom: 0 }}
      >
        <MdInfoOutline
          color={mode === 'light' ? color._616161 : color._C0C0C0}
        />
      </IconButton>
      <Drawer
        anchor="right"
        open={isDrawerShown}
        onClose={(event) => {
          event.stopPropagation();
          toggleDrawer(false);
        }}
        sx={{ width: '500px' }}
      >
        <Styled.DrawerMainContainer
          data-testid="kpi-definition-drawer-container"
          themeMode={mode}
        >
          <Styled.DrawerHeaderContainer
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Stack sx={{ padding: '16px' }}>
              <IconButton
                sx={{ justifyContent: 'right' }}
                onClick={(event) => {
                  event.stopPropagation();
                  toggleDrawer(false);
                }}
                style={{
                  display: 'flex',
                  alignSelf: 'flex-end',
                  width: 'fit-content',
                }}
                data-testid="btn-close-drawer"
              >
                <MdOutlineClose
                  color={mode === 'light' ? color._616161 : color._C0C0C0}
                />
              </IconButton>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '18px',
                  padding: '8px 0px',
                  marginRight: '30px',
                  fontWeight: '700',
                }}
              >
                {constants?.dataDefinitions}
              </Typography>
            </Stack>
          </Styled.DrawerHeaderContainer>
          <Styled.KpiDetailContainer
            themeMode={mode}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Stack>
              {ppSubHeadingNote && (
                <Typography className="kpiName">
                  {getSubHeading()
                    .split('\n')
                    .map((line) => (
                      <span key={line} style={{ display: 'block' }}>
                        {line}
                      </span>
                    ))}
                </Typography>
              )}
              <Typography className="definitionSectionTitle">
                {title}
              </Typography>
              {definitionList}
            </Stack>
          </Styled.KpiDetailContainer>
        </Styled.DrawerMainContainer>
      </Drawer>
    </span>
  );
}
