export const languageNames = {
  EnglishUS: 'en-US',
};

export const constants = {
  env: {
    devEnv: 'https://apim-latam.dev.mypepsico.com/it/eaip/v1/apprtreportapi/',
    // devEnv: "https://api-na.dev.mypepsico.com/it/eaip/v1/apprtreportapi/",
    qaEnv: 'https://apim-latam.qa.mypepsico.com/it/eaip/v1/apprtreportapi/',
    preProdEnv:
      'https://apim-latam.preprod.mypepsico.com/it/eaip/v1/apprtreportapi/',
    prodEnv: 'https://apim-latam.mypepsico.com/it/eaip/v1/apprtreportapi/',
    // qaEnv: "https://api-na.qa.mypepsico.com/it/eaip/v1/apprtreportapi/",
    devUI: 'http://localhost:3000/login',
  },
  IDLE_TIME: 3600000, // 1 hr
  programs: {
    SE: 1,
    HCT: 2,
    DSD: 3,
    Automation: 4,
    RGM: 5,
    Canvas: 6,
    DX: 7,
    DTC: 8,
    Manufacturing: 9,
    DTV: 10,
    AFH: 11,
    IBP: 12,
    GPG: 13,
  },
  products: {
    PepsiConnect: 1,
    R360: 2,
    GuidedSelling: 4,
    E2EWaste: 5,
    MLT: 6,
    ShipmentVisibilty: 7,
    Automation: 8,
    PromoAI: 9,
    Canvas: 10,
    PricingAI: 11,
    DTC: 12,
    ManufacturingControlTower: 13,
    ShouldCostModelling: 14,
    MenuPro: 15,
    o9Solutions: 16,
    PepsiTastyRewards: 17,
    GPG: 18,
    MFGPro: 20,
    APP: 21,
    Archlet: 22,
    Kazandirio: 23,
  },
  sectors: {
    LATAM: 1,
    APAC: 2,
    Europe: 3,
    PFNA: 4,
    PBNA: 5,
    AMESA: 6,
  },
  markets: {
    colombia: 7,
    brazil: 11,
    dominicanRepublic: 20,
    global: 999999,
  },
  modals: {
    suggestedOrder: 1,
    productPairing: 2,
    shareWise: 3,
    nbA: 4,
  },
  tabs: {
    overview: 'Overview',
    valueCreation: 'Value Creation',
    appPerformance: 'App Performance',
    userAdoption: 'User Adoption',
  },
  subTabs: {
    busMetrics: 'Business Metrics',
    orderBoard: 'Order Board',
    storeLevel: 'Store Level',
    highLevel: 'High Level',
  },
  onboarding: {
    title: 'Product Success Dashboard',
    text: 'Welcome back! login with your credentials',
    loginWithSaml: 'Login with SAML',
    loginHelp: 'Login Help',
    pdfLink:
      'https://pepsico.sharepoint.com/:b:/t/DSS-ProductSuccessDashboard/EaDIthZhTCtHjD7qILc-M5gBvDmcp9cRup_FZr1JxR48zA?e=on0awq',
  },
  filterBarPlaceHolder: {
    program: 'Program',
    product: 'Product',
    sector: 'Sector',
    market: 'Market',
    customer: 'Customer',
    model: 'Model',
    site: 'Site',
  },
  orderStatus: {
    orderAnaysis: 'Order Analysis', //(End Of Day)
    ordersInTheEndOfDay: 'ORDERS IN THE LAST 24 HOURS',
    ordersInLastSevenDays: 'ORDERS IN THE LAST 7 DAYS',
    ordersInLast4Weeks: 'ORDERS IN THE LAST 12 WEEKS',
    ordersInLast6Months: 'ORDERS IN THE LAST 6 MONTHS',
    orders: 'ORDERS',
    revenue: 'REVENUE',
  },
  period: {
    last24hours: 1,
    daily: 7,
    weekly: 28,
    monthly: 182,
    yearly: 365,
  },
  rgmUserAdoptionFilters: [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'External',
      value: 'External',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
  ],
  platform: {
    android: 'Android',
    ios: 'iOS',
  },
  dynamicKPIs: {
    appPerformanceKPIs: {
      getIncidentMonitoringSlaAnalysis: 'getIncidentMonitoringSlaAnalysis',
      getIncidentTrend: 'getIncidentTrend',
      getIncidentMonitoringOpenAndClose: 'getIncidentMonitoringOpenAndClose',
      getIncidentMonitoringPriorityLevelGraph:
        'getIncidentMonitoringPriorityLevelGraph',
      getIncidentMonitoringTableView: 'getIncidentMonitoringTableView',
      getIncidentMonitoringOverview: 'getIncidentMonitoringOverview',
      getApdexScore: 'getApdexScore',
      getTopSlowestAPI: 'getTopSlowestAPI',
      getPageResponseTrend: 'getPageResponseTrend',
      getErrorRateTrend: 'getErrorRateTrend',
      getBrowserErrorTrend: 'getBrowserErrorTrend',
      getAPIResponseTrend: 'getAPIResponseTrend',
      getApiCallsTrend: 'getApiCallsTrend',
      getTopErrorAPI: 'getTopErrorAPI',
      getTopAPIRequest: 'getTopAPIRequest',
    },
    valueCreation: {
      getStoreReachingTarget: 'getStoreReachingTarget',
      getStoreUsage: 'getStoreUsage',
    },
    userAdoptionKPIs: {
      getRageClick: 'getRageClick',
      getUserAdoptionScorecard: 'getUserAdoptionScorecard',
      getTopEntryPage: 'getTopEntryPage',
      getActiveAndTotalUsersChart: 'getActiveAndTotalUsersChart',
      getAdoptionRateTrend: 'getAdoptionRateTrend',
    },
  },
  powerBIServices: {
    psdLite: 'psdlite',
  },
  b2bDigitalOrder: {
    digitalRevenue: 'Digital Revenue as % of Total revenue',
    nonDigitalRevenue: 'Non Digital Revenue as % of Total revenue',
  },
  processingDaysSaved: {
    processingDays: 'Processing Days',
    daysSaved: 'Days Saved',
    daybaseline: '30-day Baseline',
  },
  comingSoonText: 'Data Visual Coming Soon...',
  noDataToDisplay: 'No data to display',
  operationalMetrics: {
    activeStores: 'ACTIVE STORES',
    shareOfDigitalOrders: 'SHARE OF DIGITAL ORDERS',
    incNetRevenue: 'INCREMENTAL NET REVENUE',
    avgLogin: 'AVERAGE LOGIN',
    perActiveStore: 'PER ACTIVE STORE',
    totalHeader: 'Total',
    digitalHeader: 'Digital',
    avgNetRevenue: `Average Net Revenue / Store (${String.fromCharCode(0x24)})`,
    avgOrderStore: 'Average Order / Store (% of Orders)',
    avgOrderSize: `Average Order Size / Store (${String.fromCharCode(0x24)})`,
    storesEngaged: 'STORES ARE ENGAGED',
    rewardRedeemingStores: 'REWARDS REDEEMING',
    totalPointsEarned: 'Total Points Earned',
    totalPointsRedeemed: 'Total Points Redeemed',
    orderedOnceSinceRegistration: 'ORDERED ONCE SINCE REGISTRATION',
    frequentOrderingStores: 'FREQUENT ORDERING STORES',
    totalDigitalOrders: 'TOTAL DIGITAL ORDERS',
    activeOrderingStore: 'ACTIVE ORDERING STORES',
    totalNonDigitalOrders: 'TOTAL NON-DIGITAL ORDERS',
    incrementalNetRevenue: 'INCREMENTAL NET REVENUE',
    averageTimeInPlatform: 'AVERAGE TIME IN PLATFORM',
    digitalizeStores: 'DIGITALIZE STORES',
    targetRegistration: 'Target Registration',
    monthlyActiveStores: 'Active Stores',
    totalNetRevenue: 'TOTAL NET REVENUE',
    totalIncrementalCMYTD: 'TOTAL INCREMENTAL CM YTD',
    totoalIncrementalCMImpactPerMonth: 'TOTAL INCREMENTAL CM IMPACT PER MONTH',
    netRevenueIncrementality: 'Net Revenue Incrementality',
    storeData: 'Store Data',
    storeUsage: 'Store Usage',
    digitizedStores: 'Digital Stores',
    digitalOrders: 'Digital Orders',
    salesAndDAndA: 'Sales & D&A (Discount and Allowance)',
    digitalOrderingBehavior: 'Digital Ordering Behavior',
    accrued: 'ACCRUED',
    realized: 'REALIZED',
    adoptionKpis: "Adoption KPI's",
    suggestedOrder: 'Suggested Order',
    storeAdoptionFunnel: 'Store Adoption Funnel',
    storeAdoptionOverTime: 'Store Adoption Over Time',
    netPromoterScore: 'Net Promoter Score',
    executiveKpis: "Executive KPI's",
    executionKpis: "Execution KPI's",
    revenueIndicator: 'Revenue Indicator',
    averageSoSizeIndexOverTime: 'Average So Size Index Over Time',
    valueEngagementIndicator: 'Value/Engagement Indicator',
    accuracyEngagementIndicator: 'Accuracy/Engagement Indicator',
    technicalKpis: "Technical KPI's",
    financialKpis: "Financial KPI's",
    salesUplift: 'Sales Uplift',
    salesupliftOverTime: 'Sales Uplift Over Time',
    modalRecall: 'MODEL RECALL',
    modalPrecision: 'MODEL PRECISION',
    Recall: 'RECALL',
    Precision: 'PRECISION',
    f1Score: 'F1 SCORE',
    suggestedOrderVolumeIndex: 'Suggested Order Volume Index',
    suggestedOverallVolumeIndex: 'Suggested Overall Volume Index',
    suggestedOrderBaskets: 'Suggested Order Baskets',
    orderValueGrowth: 'Order Value Growth',
    targetPerformanceFunnel: 'Target Performance Funnel',
    storeEngagement: 'Store Engagement',
    rewardSummary: 'Reward Summary',
    salesDAIncrementalRevenue: 'D & A of Incremental Net Revenue',
    markets: 'Markets',
    totalUserCount: 'TOTAL USER COUNT',
    numberOfMarkets: 'NUMBER OF MARKETS',
    numberOfBrands: 'NUMBER OF BRANDS',
    numberOfCreatedScenarios: 'NUMBER OF CREATED SCENARIOS',
    brands: 'Brands',
    campaignRegisters: 'Campaign Registers by Initiative - Top 3',
    rewardsNEngagement: {
      rewardTitle: 'Rewards',
      pointsAccumulated: 'POINTS ACCUMULATED DURING THE PERIOD',
      pointsSpent: 'POINTS SPENT DURING THE PERIOD',
      pointsTitle: 'Points',
      engagementTitle: 'Engagement Activities',
      activitiesInStores: 'Activities in Stores',
      percentageOfVideos: '% OF VIDEOS',
      percentageOfGames: '% OF GAMES',
      percentageOfSurveys: '% OF SURVEYS',
    },
    totalPoints: 'TOTAL POINTS',
    generatedFromSales: 'GENERATED FROM SALES',
    generatedFromActivity: 'GENERATED FROM ACtIVITY',
    generatedFromTargetHits: 'GENERATED FROM TARGET HITS',
    generateFromEngagementActivity: 'GENERATED FROM ENGAGEMENT ACTIVITY',
    storesWatchingVideos: 'STORES WATCHING VIDEOS',
    storesPlayingGames: 'STORES PLAYING GAMES',
    storesTakingSurveys: 'STORES TAKING SURVEYS',
    PointsEarnedByStores: 'Points Earned by Stores',
    forVideos: 'FOR VIDEOS',
    forGames: 'FOR GAMES',
    forSurveys: 'FOR SURVEYS',
    shipmentVisibilty: {
      stores: 'Stores',
    },
    r360OperationalKPIS: {
      phaseUsageRate: 'PHASE USAGE RATE',
      callVolume: 'CALL VOLUME',
      totalNoOfVisitsKAM: 'TOTAL NUMBER OF VISITS/KAM',
      totalNoOfVisitsNAM: 'TOTAL NUMBER OF VISITS/NAM',
      noOfDocumentUploaded: 'NUMBER OF DOCUMENTS UPLOADED',
      conversionGrowth: 'EQUIPMENT COMPLIANCE CONVERSION GROWTH',
    },
    r360OperationalJBPKPIs: {
      contentEntryRate: 'CONTENT ENTRY RATE',
      numberOfInnovationItemsEntered: 'NUMBER OF INNOVATION ITEMS ENTERED',
      numberOfMeetingMinutesUploaded: 'NUMBER OF MEETING MINUTES UPLOADED',
      numberOfDocumentsUploaded: 'NUMBER OF DOCUMENTS UPLOADED',
      actionItemsConversionRate: 'ACTION ITEMS CONVERSION RATE',
    },
    phaseUsageRate: 'Phase Usage Rate',
    jointBusinessPlan: 'JBP (Joint Business Plan)',
    r360customerCalls: {
      customerCalls: 'Customer Calls',
      exportsPerPeriod: 'EXPORTS (#) PER PERIOD',
      totalVisitsKam: 'TOTAL # VISITS/KAM',
      totalVisitsNam: 'TOTAL # VISITS/NAM',
      callVolume: 'CALL VOLUME',
    },
    subCustomer: 'Sub Customer',
    sharewiseRecommendations: 'Sharewise Recommendations',
    sharewiseRecommendationsAddedToSellSheet:
      'Sharewise Recommendations added to sell sheet',
    totalRecommendations: 'Total Recommendations',
    sharewiseRecommendationsAddedToFinalizedSale:
      'Sharewise Recommendations added to sell sheet or finalized in sale',
    storeAcceptanceRateHeader: 'Store Acceptance Rate',
    storeAcceptanceRateSubHeader:
      'Stores with at least 1 recommendation added/Total Stores with Recommendation',
    totalInstalls: 'Total Installs',
    installsYearlyTarget: 'INSTALLS YEARLY TARGET',
    registersYearlyTarget: 'REGISTERS YEARLY TARGET',
    totalRegisters: 'Total Registers',
    businessMetrics: 'Business Metrics',
    registersMix: 'Registers Mix',
    totalRegistersByWhatsApp: 'Total Registers by WhatsApp',
    totalRegistersByWeb: 'Total Registers byWeb/F4J',
    totalRegistersByLeadGenerator: 'Total Registers by Lead Generator',
    totalRegistersByApp: 'Total Registers by App',
    personaToUserMapping: 'Persona to User Mapping',
    personaUsage: 'Persona Usage',
    mctAlerts: {
      alerts: 'Alerts',
      valueRealization: 'Value Realization',
      alertsByState: 'Alerts by State',
      alertsAssignment: 'Alerts Assignment',
      alertsBySeverity: 'Alerts by Severity',
      openAlerts: 'OPEN ALERTS',
      closedAlerts: 'CLOSED ALERTS',
      assignedAlerts: 'Assigned Alerts',
      unassignedAlerts: 'Unassigned Alerts',
    },
    restaurantsData: 'Menus Data',
    restaurantsOnboarded: 'NUMBER OF RESTAURANT MENUS',
    restaurantsWithMenuScoreHeader: 'MENUS WITH MENU SCORE > 66%',
    restaurantsWithPepsiBeverage: 'MENUS WITH PEPSI BEVERAGE',
    newMenusToAudit: 'NEW MENUS TO AUDIT',
    restaurantsWithMenuScore: 'Menus with Menu Score',
    restaurantsWithBeverages: 'Menus with Beverages',
    efficiency: 'Efficiency',
    utility: 'Utility',
    quality: 'Quality',
    soRevenue: 'SO Revenue',
    soDrivenOrders: 'SO Driven Orders',
    scenarios: 'Scenarios',
    scenariosCreated: 'Scenarios Created',
    numberOfScenariosCreated: 'Number of Scenario’s Created',
    shareLoss: 'Share Loss',
    shareLossByCategory: 'Share loss by Category',
    tasksResponded: 'Tasks Responded',
    salesUpliftTrendLine: 'Sales Uplift % Trend line',
    paymentAuthorizationRate: 'Payment Authorization Rate',
    refundRate: 'Refund Rate',
    paymentAuthorizationRateVolume: 'Payment Authorization Rate & Volume',
    refundRateVolume: 'Refund Rate & Volume',
    transactionMetrics: 'Transaction Metrics',
    totCompletedPaymentValue: 'Total Completed Payment Value',
    totPaymentValue: 'Total Payment Value',
    avgCompletedPaymentValue: 'Avg Completed Payment Value',
    totCompletedPayments: 'Total Completed Payments',
  },
  beesVsPepsiConnectMetrics: {
    beesVsPepsiConnectMetrics: 'BEES vs PepsiConnect Metrics',
    ordersInBees: 'ORDERS IN BEES',
    grInBees: 'GR IN BEES',
    ordersInPepConnect: 'ORDERS IN PEPSICONNECT',
    grInPepConnect: 'GR IN PEPSICONNECT',
    orders: 'Orders',
    dropsizeOrders: 'Dropsize Orders ($)',
    storesReachingTargets: 'Stores Reaching Targets',
    grossRevenueIn: 'Gross Revenue in ($)',
  },
  applicationPerformance: {
    errorCrashRateTrend: 'Error / Crash Rate Trend',
    deviceOsBreakdown: 'Device / OS Breakdown',
    storesreachingOneProductOrMonthlyTarget:
      '* Stores reaching one product or monthly target',
    slaAnalysis: 'SLA Analysis',
    incidentsMeetingSLAPercentageHeader: 'Incidents',
    incidentsMeetingSLAPercentageSubHeader: 'Meeting SLA (%)',
    incidentsAtRiskOfSLABreachPercentageHeader: 'Incidents at Risk',
    incidentsAtRiskOfSLABreachPercentageSubHeader: 'of SLA Breach(%)',
    incidentsOfSLABreachPercentageHeader: 'Incidents of',
    incidentsOfSLABreachPercentageSubHeader: 'SLA Breach (%)',
    serviceLevelAgreement: '*SLA : Service Level Agreement',
    incidentTrend: 'Incident Trend',
    serviceNowURL:
      'https://pepsico.service-now.com/nav_to.do?uri=incident.do?sysparm_query=number=',
    healthStability: 'Health & Stability',
    incidentMonitoring: 'Incident Monitoring',
    avgAPILatency: 'AVERAGE API LATENCY',
    appCrashRate: 'APP CRASH RATE',
    assignedGroupNames: ['PepsiConnect', 'OCH', 'CEP'],
    coreWebVitals: 'Core Web Vitals',
  },
  applicationPerformanceRedesigned: {
    apiPerformance: 'API Performance',
    applicationPerformance: 'App Performance',
    networkLatency: 'Network Latency',
    apiResponseTimes: 'API Response Times',
    topSlowestApis: 'Top 5 Slowest APIs (Last 24 hours)',
    errorRate: 'Error Rate',
    userExperience: 'User Experience',
    uniqueUser: 'Unique User',
    appCrashReport: 'App Crash Report',
    bounceRateST: 'Bounce Rate ST',
    activeUsers: 'Active Users',
    avgSessionTime: 'Avg Session Time',
    incidentMonitoring: 'Incident Monitoring',
    incidentTrend: 'Incident Trend',
    slaAnalysis: 'SLA Analysis',
    apdexScore: 'Apdex Score (Last 24 Hours)',
    apdexScorePromoAI: 'Apdex Score',
    appUpTime: 'App Up Time',
    crashRate: 'Crash Rate',
    dailyUsers: 'Daily Active Users',
    avgAPIRespTime: 'AVG API App Response Time',
    monthlyIncident: 'Monthly Incident',
    category: 'Category',
    assignmentGroup: 'Assignment Group',
    selectPriority: 'Priority',
    selectStatus: 'Status',
    apiCallsPerMinute: 'API Calls Per Minute',
    apiErrorTrend: 'API Error Trend',
    browserError: 'Browser Error',
    pageResponseTimes: 'Page Response Times',
    backend: 'Backend',
    avgApiRespTime: 'AVG API RESPONSE TIME',
    totalNoOfErrors: 'TOTAL NUMBER OF ERRORS',
    applicationErrors: 'APPLICATION ERRORS',
    apiRequests: 'Top 5 API Requests (Last 24 hours)',
    apiErros: 'Top 5 API Errors (Last 24 hours)',
    serverSideCalendar: 'Server-Side Calendar Generation Runtime (Median)',
    frequency: 'Frequency',
    valuableActionsTaken: ' Valuable Actions Taken',
    totalActionsTaken: 'Total Actions Taken',
    calendarGeneration: 'Calendar Generation',
    timeSpentByWorkflow: 'Time Spent by Workflow',
    totalCalendarCreated: 'Total Calendar Created',
  },
  overview: {
    totalUser: 'Total Users',
    activeUser: 'Active Users',
    newUser: 'New Users',
    topEntryPages: 'Top Entry Pages',
    topFeatures: 'Top Features',
    rageClicks: 'Rage Clicks',
    avgLoginPerActiveStore: 'AVG LOGIN/ACTIVE STORE',
    avgTimeInThePlatform: 'AVG TIME IN PLATFORM',
    uniquePageViews: 'UNIQUE PAGE VIEWS',
    userFrequency: 'USER FREQUENCY',
    activeSessionTime: 'ACTIVE SESSION TIME',
    noOfUsers: 'NUMBER OF USERS',
    uniqueSessions: 'UNIQUE SESSIONS',
    totalUsers: 'TOTAL USERS',
    activeUsers: 'ACTIVE USERS',
    userBehaviorAndEngagement: 'User Adoption',
    valueCreation: 'Value Creation',
    userAdoption: 'User Adoption',
    appPerformance: 'App Performance',
    revenue: 'SO REVENUE',
    basketSize: 'SO BASKET SIZE',
    skuPerOrder: 'SKUS PER ORDER',
    skuAdded: 'SKUS ADDED',
    skuRemoved: 'SKUS REMOVED',
    drivenOrders: 'SO DRIVEN ORDERS',
    storeUniverse: 'SO STORE UNIVERSE',
    operationalMetrics: 'Value Creation',
    allRecommendationsServed: 'All Recommendations Served',
    productPairingRecommendation: 'PFNA Product Pairing',
    recommendationsSellSheet: 'Recommendations added to Sell Sheet Overtime',
    appRecommendation: 'App Recommendations',
    appRecommendationSubText: 'Acceptance Rate',
    appRecommendationSubHeader:
      'Product Pairing added to sell sheet/all Product Pairing served',
    regionComparison: 'Region Comparison',
    regionRecommendationsSellSheet: 'Recommendations added to sell sheet',
    totalRecommendation: 'Total number of recommendations',
    dwellTime: 'Dwell Time',
    avgDwellTime: 'AVG DWELL TIME',
    dwellTimeDeviation: 'DWELL TIME DEVIATION',
    avgDeliveriesPerWeek: 'AVERAGE DELIVERIES PER WEEK',
    etaWindowAccuracyPlanned: 'ETA WINDOW ACCURACY (PLANNED)',
    etaWindowAccuracyStarted: 'ETA WINDOW ACCURACY (STARTED)',
    etaWindowAccuracyEnroute: 'ETA WINDOW ACCURACY (ENROUTE)',
    solutionMonitoringTitle: 'Solution Monitoring',
    solutionMonitoring: {
      adapterService: 'Adapter Service',
      visibilityService: 'Visibility Service',
      subscriptionService: 'Subscription Service',
      upTime: 'Uptime',
      msgReceived: 'Messages Received',
      msgProcessed: 'Messages Processed',
      msgSuccessRate: 'Messages Success Rate',
      sequencing: 'Sequencing',
      shipmentMissingEvent:
        'shipment missing event (planned - started - enroute - arrived)',
    },
    totalShipments: 'TOTAL SHIPMENTS',
    totalStores: 'TOTAL STORES',
    performanceTitle: 'Performance',
    processingTimeTitle: 'Processing Time',
    processingTime: {
      adapterService: 'ADAPTER PROCESSING TIME',
      visibilityService: 'VISIBILITY SERVICE PROCESSING TIME',
      subscriptionService: 'SUBSCRIPTION SERVICE PROCESSING TIME',
      average: 'AVERAGE PROCESSING TIME OF ALL STEPS',
    },
    adoption: 'Adoption',
    overallActivity: 'Overall Activity',
    TotalStoreInMarketWithNBA: 'Total Unique stores in market with NBA',
    totalNBAaccepted: 'Total NBA’s accepted',
    totalNbaDisplayed: 'Total nba’s displayed',
    totalStoreAdoption: 'Total store adoption',
    TotalNbaAdoption: 'Total nba adoption',
    nbaStoreAdoption: 'Store Adoption',
    adoptionRate: 'NBA Adoption',
    nBAComparison: 'NBA Comparison',
    nBAs: 'NBAs',
    storesInMarketWithNBA: 'Unique stores in market with NBA',
    nbasAccepted: 'NBAS ACCEPTED',
    nbasDisplayed: 'NBAS DISPLAYED',
    storeAdoption: 'STORE ADOPTION',
    nbaAdoption: 'NBA ADOPTION',
    salesGrowth: 'Sales Growth',
    sitePerformance: 'Site Performance',
    deadClicks: 'Dead Clicks',
    userEngagement: 'User Engagement',
    userBehaviorEngagement: 'User Behavior & Engagement',
    IndexVsPYSelectedMonth: 'Index vs PY Selected Month',
    processingDaysSaved: 'Processing Days Saved',
  },
  latamLeadershipView: {
    adoption: 'Adoption',
    performance: 'Performance',
    engagement: 'Engagement',
    digitalSales: 'Digital Sales',
    fullDigiCustomers: 'Full Digital Customers',
    toplineVsControl: 'Top Line vs Control (Sales)',
    volVsControl: 'Volume vs Control (KG)',
    customerBelowRedemption: 'Customers Below Redemption Threshold',
    fullyDigitalCustomers: '% of Fully Digital Customers',
    customersDigitalSegmentation: 'Customers Digital Segmentation',
    customerRedeemingHeader: 'Customer Redeeming 30-90 Days',
    customerRedeemingText: 'CUSTOMER REDEEMING',
    customerReachingTarget: 'Customer Reaching Target',
    customerEligibleToRedeem: 'Customer eligible to redeem	',
    algoSellingAdoption: 'ALGO Selling Adoption (Customers)',
  },
  productPairing: {
    storesWithPPRecommendation: 'STORES WITH PRODUCT PAIRING RECOMMENDATIONS',
    totalRecommendation: 'TOTAL NUMBER OF RECOMMENDATIONS',
    totalPPRecommendationInSellSheet:
      'PRODUCT PAIRING RECOMMENDATIONS (ADDED TO OR FINALIZED IN SELL SHEET)',
    projectedMonthlyRevenueLift: 'PROJECTED MONTHLY REVENUE LIFT',
    avgSuggestedQtyOfProductOne:
      'AVG SUGGESTED QUANTITY OF ANCHOR PRODUCT (EACHES)',
    avgSuggestedQtyOfProductTwo:
      'AVG SUGGESTED QUANTITY OF COMPLIMENTARY PRODUCT (EACHES)',
    subHeading:
      'The KPIs have a 1 calendar week lag as writebacks can only be refreshed weekly due to business requirements to use last calendar week’ actions to impact current week recommendations based on DSL actions',
  },
  shareWise: {
    subHeading:
      'The KPIs have a 1 calendar week lag as writebacks can only be refreshed weekly as DSLs have week to act upon recommendation \n Note: Recommendations are generated for subcategories where PepsiCo holds at least a 5% market share in the store, to ensure accuracy',
  },
  nba: {
    subHeading:
      'Please refer to PSD from Dec 2024 onwards, as there were some writebacks not flowing in and were fixed during hypercare.',
  },
  dtvScm: {
    valueRealization: 'Value Realization',
    totalOpportunity: 'Total Opportunity',
    gapImproved: 'Gap Improved',
    numberOfSku: "Number of SKU's",
    baselinesUploaded: 'Baselines uploaded',
    baselineTemplatesDownloaded: 'baseline templates downloaded',
    baselineRatio: 'baseline ratio',
    totalBaselineSpend: 'Total Baseline Spend',
    valueOpportunityIdentified: 'Value Opportunity Identified',
    totalProjectsCreated: 'Total Projects Created',
    totalHoursSaved: 'Total Hours Saved',
  },
  userBehavior: {
    digitalOrderUserAnalytics: 'Digital Order User Analytics',
    basketOrCheckout: 'Basket or Checkout',
    easyOrder: 'Easy Order',
    easyOrderTabVisitors: 'EASY ORDER TAB VISITORS',
    easyOrderAvgTime: 'EASY ORDER AVG TIME',
    yourFavoritesTabVisitors: 'YOUR FAVORITES TAB VISITORS',
    yourFavoritesAvgTime: 'YOUR FAVORITES AVG TIME',
    yourFavorites: 'Your Favorites',
    completedOrder: 'Completed Order',
    loginText: 'Login',
    forgotPwdScreen: 'Forgot Password Screen',
    forgotPwdText: 'Forgot Password',
    didntOrder: "Didn't Order",
    basketScreen: 'Basket Screen',
    orderConfirmationScreen: 'Order Confirmation Screen',
    usersText: 'Users',
    conversionRate: 'Conversion Rate',
    medTimeToConvert: 'Median Time To Convert',
    regularUsers: 'Regular Users',
    pepsiConnectUsers: 'PEPSI CONNECT USERS',
    changeFromPreviousPeriod: 'change from previous period',
    pepsiConnectUsersWithMoreThanOneSession:
      'PEPSI CONNECT USERS WITH MORE THAN 1 SESSION',
    orderConfirmationBasketAgainToCheck:
      'Order Confirmation > Basket Again to Check',
    orderHistoryOrderDetail: 'Order History > Order Detail',
    anyActivity: 'Any Activity',
    orderHistory: 'Order History',
    orderDetail: 'Order Detail',
    profileMgmtUserAnalytics: 'Profile Management User Analytics',
    customerEngagementUserAnalytics: 'Customer Engagement User Analytics',
    anyActivityEasyOrders: 'Any Activity > Easy Orders',
    homePagePromotions: 'Home Page > Promotions For You',
    users: 'users',
    medianTimeToConvert: 'Median time to convert',
    homeScreen: 'Home Screen',
    homePage: 'Home Page',
    recommendations: 'Recommendations',
    results: 'Results',
    promotionCatalogue: 'Promotion Catalogue',
    targets: 'Targets',
    rewardsCatalogue: 'Rewards Catalogue',
    videosAndSurveys: 'Videos and Surveys',
    noOfUsers: '# of users',
    avgTimeOnPage: 'avg time on page',
    activeUsers: 'ACTIVE USERS',
    valuableActionsTaken: 'Valuable Actions Taken',
    medianTimeInPlatform: 'Median Time in Platform',
    numberOfUsers: 'Number of Users',
    adoptionRate: 'Adoption Rate',
    adoptionLogin: 'Logins per week (per user)',
    adoptionFrequency: 'Adoption Frequency',
    mostPopular: 'Most Popular Pages',
    mostVisited: 'Most Visited Pages',
    lcpPercentile: 'LCP (75th percentile)',
    fidPercentile: 'FID (75th percentile)',
    clsPercentile: 'CLS (75th percentile)',
    adoptionRateUserBehavior: 'ADOPTION RATE',
    userInteraction: 'User Interaction',
    userJourney: 'User Journey Conversion Funnels',
    journeyAndInteractionMetrics: 'Journey & Interaction Metrics',
    topPagesByEngagement: 'Top Pages by Engagement',
    mostViewed: 'Most Viewed',
    leastViewed: 'Least Viewed',
    timeOfDayForOrders: 'Time of Day for Orders (Last 7 Days)',
    timeOfDayForLogins: 'Time of Day for Logins (Last 7 Days)',
    afhRecommendations: 'Recommendations Page',
    marketsSupported: 'Markets Supported',
    avgTechincalForecast: 'Average Technical Forecast Accuracy',
    totalUser: 'Total Users',
    activeUser: 'Active Users',
    totalAccountsOnboarded: 'Total Accounts Onboarded',
    userBehaviourAndEngagement: 'User Behaviour and Engagement',
  },
  automation: {
    tools: 'Tools',
    projects: 'Projects',
    clearAll: 'Clear all',
    vsLastPeriod: 'vs Last Period',
    valueMetrics: 'Value Metrics',
    valueGenerated: 'Value Generated',
    adoptionMetrics: 'Adoption Metrics',
    adoptionAchieved: 'Adoption Achieved',
    healthMetrics: 'Health Metrics',
    healthSummary: 'Health Summary',
  },
  userRole: 'Executive',
  dataDefinitions: 'Data Definitions',
  timeRangeOptions: [
    { value: 7, label: 'Daily' },
    { value: 28, label: 'Weekly' },
    { value: 182, label: 'Monthly' },
  ],
  globalIDs: {
    sectorId: 99,
    marketId: 999999,
  },
};
export const defaultValues = {
  programs: [
    {
      programId: 1,
      programName: 'SE',
      programFullName: 'Sales Effectiveness',
      products: [
        {
          productId: 1,
          productName: 'PepsiConnect',
          sectors: [
            {
              sectorId: 1,
              sectorName: 'LATAM',
              markets: [
                {
                  marketId: 7,
                  marketName: 'Colombia',
                  applicationNames: ['Android', 'iOS'],
                },
                {
                  marketId: 20,
                  marketName: 'Dominican Republic',
                  applicationNames: ['Android', 'iOS'],
                },
                {
                  marketId: 11,
                  marketName: 'Brazil',
                  applicationNames: ['Android', 'iOS'],
                },
              ],
            },
            {
              sectorId: 2,
              sectorName: 'APAC',
              markets: [
                {
                  marketId: 17,
                  marketName: 'New Zealand',
                  applicationNames: ['Android', 'iOS'],
                },
              ],
            },
            {
              sectorId: 3,
              sectorName: 'Europe',
              markets: [
                {
                  marketId: 13,
                  marketName: 'Spain',
                  applicationNames: ['Android', 'iOS'],
                },
                {
                  marketId: 14,
                  marketName: 'Turkey',
                  applicationNames: ['Android', 'iOS'],
                },
              ],
            },
          ],
        },
        {
          productId: 4,
          productName: 'Guided Selling',
          sectors: [
            {
              sectorId: 1,
              sectorName: 'LATAM',
              markets: [
                {
                  marketId: 7,
                  marketName: 'Colombia',
                  applicationNames: [],
                },
                {
                  marketId: 20,
                  marketName: 'Dominican Republic',
                  applicationNames: [],
                },
              ],
            },
            {
              sectorId: 3,
              sectorName: 'Europe',
              markets: [
                {
                  marketId: 13,
                  marketName: 'Spain',
                  applicationNames: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      programId: 7,
      programName: 'DX',
      programFullName: 'Demand Accelerator',
      products: [
        {
          productId: 2,
          productName: 'R360',
          sectors: [
            {
              sectorId: 4,
              sectorName: 'PFNA',
              markets: [],
            },
          ],
        },
      ],
    },
  ],
  categories: [
    'DATA MISSING / OUT-OF-DATE / CORRUPT',
    'ALERT / ERROR / WARNING',
    'UNAVAILABLE',
    'BROKEN / DAMAGED',
    'PREVENTATIVE ACTION REQUESTED',
    'POOR PERFORMANCE',
    'OBJECTION / COMPLAINT',
    'OTHER',
    'CANNOT LOG IN',
  ],
};

export const generateCommonStyles = (additionalStyles) => ({
  ...additionalStyles,
  multiValue: (provided) => ({
    ...provided,
    padding: '0px 0px 2px 2px',
    height: '18px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
    padding: '1px 2px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '26px',
  }),
});

export const selectCustomStyles = generateCommonStyles({
  control: (provided) => ({
    ...provided,
    // background: "transparent",
    borderColor: '#545f6f',
    boxShadow: 'none',
    minHeight: '32px',
    '&:hover': {
      borderColor: '#545f6f',
    },
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 8px',
    width: 'auto',
    height: '32px',
    background: '#272727',
    border: '1px solid #3A3A3A',
    borderRadius: '4px',
    flex: 'none',
    order: '0',
    flexGrow: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#EFEFEF',
    fontSize: '14px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#999999' : '#EFEFEF',
    fontSize: '14px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '26px',
    padding: '2px 16px 2px 10px',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#666666',
    alignSelf: 'center',
    padding: '0px',
    marginTop: '5px',
    '&:hover': {
      color: '#333333',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
});

export const customSelectStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#999999' : '#EFEFEF',
    fontSize: '14px',
    marginBottom: '5px',
    marginLeft: '-10px',
  }),
};

export const screenSizes = {
  mobile: '(max-width: 600px)',
  // tablet: "(min-width: 601px) and (max-width: 1024px) and (device-width: 601px) and (device-height: 1024px)",
  tablet: '(min-width: 601px) and (max-width: 1024px)',
  ipadMini:
    '(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (device-width: 768px) and (device-height: 1024px)',
  ipadPro:
    '(min-width: 1024px) and (max-width: 1366px) and (device-width: 1024px) and (device-height: 1366px)',
  ipadAir: '(min-width: 820px) and (max-width: 1180px)',
  desktop: '(min-width: 1025px)',
  mediumDevice: '(min-width: 600px) and (max-width: 1200px)',
  largeDevice: '(min-width: 1200px)',
};

export const breakpoints = {
  mobile: '360px',
  tablet: '962px',
  desktop: '1200px',
};

export const rgmUserAdoptionFilters = [
  { value: 'Internal', label: 'Internal Users' },
  { value: 'External', label: 'External Users' },
  { value: 'All', label: 'All Users' },
];
