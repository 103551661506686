import styled from 'styled-components';
import { Grid } from '@mui/material';
import { color } from '../../../../theme/Color';

interface RgmAppUserAdoptionContainerProps {
  mode: string;
}

export const RgmAppUserAdoptionContainer = styled(
  Grid
)<RgmAppUserAdoptionContainerProps>`
  margin-top: 20px;
  & .infoIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  & .verticalBarContainer {
    border-width: 1px !important;
    background: ${({ mode }) =>
      mode === 'light' ? color._B2CAEA : color._666666};
    height: 85px;
  }
  & .NrmPromoAIHeader {
    font-weight: 700;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
  }

  & .NrmPromoAICard {
    background: ${({ mode }) =>
      mode === 'light'
        ? `${color._FBFCFE} !important`
        : `${color._1d1d1d} !important`};
    border: ${({ mode }) =>
      mode === 'light' ? `1px solid ${color._D7E5F8}` : ' '};
    border-radius: 8px;
    padding: 24px;
    margin-top: 20px;
  }
  & .posRelative {
    position: relative;
  }
  & .headerContainer {
    color: ${({ mode }) => (mode === 'light' ? color._616161 : color._efefef)};
    font-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 0px;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  & .cardHeader {
    color: ${({ mode }) => (mode === 'light' ? color._616161 : color._efefef)};
    font-size: 18px !important;
    font-family: Inter !important;
    font-weight: 700 !important;
    margin-top: 10px !important;
  }
`;
