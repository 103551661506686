import React, { useState, useEffect, useContext } from 'react';
import * as styled from './style';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useLayoutNavigation } from '../hooks/layout-navigation-hook';
import { FilterContext } from '../components/FilterBar/FilterProvider';
import { constants } from '../constants/Constants';
import { ThemeContext } from '../context/ThemeContext';

const Layout = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);
  const { regionValues, betaFeatureToggle } = useContext(FilterContext);
  const { mode } = useContext(ThemeContext);

  const { getPrimaryNavigation, navigation, getProfilePicDropdown } =
    useLayoutNavigation();

  useEffect(() => {
    getPrimaryNavigation();
    getProfilePicDropdown();
  }, [getPrimaryNavigation, getProfilePicDropdown]);

  const capitalizeName = (name) => {
    const words = name?.toLowerCase().split(' ');
    const capitalizedWords = words?.map(
      (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
    );
    return capitalizedWords?.join(' ');
  };

  const updatedNavigation = navigation
    ?.filter((navItem) => {
      // If the navItem title is "PSD Lite Dashboard" and betaFeatureToggle is false, exclude it
      if (navItem.title === 'PSD Lite Dashboard' && !betaFeatureToggle) {
        return false;
      }
      return true; // Include all other items
    })
    .map((navItem) => {
      // This part can stay the same as your existing logic
      if (navItem.title === 'Product Overview' && regionValues.length !== 0) {
        const programs = regionValues.map((region) => {
          if (
            region.programId === constants?.programs?.Automation ||
            region.programId === constants?.programs?.DSD
          ) {
            return {
              programId: region?.programId,
              programName: `${region.programName}`,
              programFullName: region?.programFullName,
              id: region?.programId,
              label: region?.programName,
              value: region?.programName,
            };
          } else {
            return {
              programId: region?.programId,
              programName:
                region.programId === constants?.programs?.Manufacturing ||
                region.programId === constants?.programs?.DTV
                  ? `${region.programName}`
                  : `${region.programName}: ${capitalizeName(region?.programFullName)}`,
              programFullName: region?.programFullName,
              id: region?.programId,
              label: region?.programName,
              value: region?.programName,
              products: region?.products.map((product) => ({
                productId: product.productId,
                productName: product.productName,
                id: product.productId,
                label: product.productName,
                value: product.productName,
                programId: region.programId,
                programFullName: `${region.programFullName}`,
              })),
            };
          }
        });

        return {
          ...navItem,
          programs: programs,
        };
      } else {
        return navItem;
      }
    });

  return (
    <div data-testid="layout-container">
      <Header setSidebar={setSidebar} />
      {regionValues && regionValues.length > 0 && (
        <styled.Container>
          <Sidebar
            navigation={updatedNavigation}
            sidebar={sidebar}
            setSidebar={setSidebar}
          />
          <styled.ChildDev mode={mode}>{children}</styled.ChildDev>
        </styled.Container>
      )}
    </div>
  );
};
export default Layout;
