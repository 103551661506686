import React from 'react';
import ValueCreationScorecard from 'containers/OverView/ArchletOverview/Scorecards/ValueCreationScorecard';
import { constants } from 'constants/Constants';
import SummaryMetricsRgmApp from 'containers/UserBehaviorEngagement/RGM/SummaryMetricsRgmApp';
import SummaryMetricsWrapper from 'containers/ApplicationPerformanceConfig/SummaryMetricsWrapper';
import IncidentMonitoringHOC from 'containers/ApplicationPerformanceRedesigned/IncidentMonitoringHOC';

/** Represents optional filters by program/product. */
export interface Filters {
  program?: string;
  product?: string;
}

/** A generic overview component type, which is a React functional component. */
export type OverviewComponentType = React.FC;

/** Internal registry definitions. */
interface ScoreCardRegistry {
  [program: string]: {
    [product: string]: OverviewComponentType[];
  };
}

interface CustomOverviewRegistry {
  [program: string]: {
    [product: string]: OverviewComponentType;
  };
}

/**
 * Internal registries stored at module scope (private).
 * We do NOT export these so they can be managed via the service methods below.
 */

const scoreCardRegistry: ScoreCardRegistry = {
  [constants.programs.DTV]: {
    [constants.products.Archlet]: [
      () => (
        <ValueCreationScorecard
          title={'Summary'}
          slug="archlet-valuecreation-overview"
        />
      ),
    ],
  },
  [constants.programs.RGM]: {
    [constants.products.APP]: [
      () => <SummaryMetricsRgmApp slug="user-adoption" title={'Summary'} />,
    ],
  },
  [constants?.programs?.DTC]: {
    [constants?.products?.Kazandirio]: [SummaryMetricsWrapper],
  },
};

const customOverviewRegistry: CustomOverviewRegistry = {
  [constants?.programs?.DTC]: {
    [constants?.products?.Kazandirio]: () => <IncidentMonitoringHOC />,
  },
};

export const OverviewService = {
  /**
   * Register a scorecard for a specific program + product.
   * This can be called at runtime to add new scorecards to the registry.
   */
  registerScoreCard(
    program: string,
    product: string,
    component: OverviewComponentType
  ): void {
    if (!scoreCardRegistry[program]) {
      scoreCardRegistry[program] = {};
    }
    if (!scoreCardRegistry[program][product]) {
      scoreCardRegistry[program][product] = [];
    }
    scoreCardRegistry[program][product].push(component);
  },

  /**
   * Register a custom overview for a specific program + product.
   */
  registerCustomOverview(
    program: string,
    product: string,
    component: OverviewComponentType
  ): void {
    if (!customOverviewRegistry[program]) {
      customOverviewRegistry[program] = {};
    }
    customOverviewRegistry[program][product] = component;
  },

  /**
   * Resolves scorecards based on provided filters.
   * @param filters - An object like { program: 'programA', product: 'product1' }
   * @returns Array of scorecard components.
   */
  resolveScoreCards(filters?: Filters): OverviewComponentType[] {
    if (!filters) {
      return [];
    }
    const { program, product } = filters;
    const programRegistry = (program && scoreCardRegistry[program]) || {};

    // If there's a product, return all the components registered for that product.
    if (product && programRegistry[product]) {
      return programRegistry[product];
    }
    return [];
  },

  /**
   * Resolves a custom overview component based on provided filters.
   * @param filters - An object like { program: 'programA', product: 'product1' }
   * @returns A single custom overview component function or null if none found.
   */
  resolveCustomOverview(filters?: Filters): OverviewComponentType | null {
    if (!filters) {
      return null;
    }
    const { program, product } = filters;
    const programRegistry = (program && customOverviewRegistry[program]) || {};

    if (product && programRegistry[product]) {
      return programRegistry[product];
    }
    return null;
  },

  /**
   * (Optional) Clears all registries—useful for testing or resetting state.
   */
  clearRegistries(): void {
    Object.keys(scoreCardRegistry).forEach((prog) => {
      delete scoreCardRegistry[prog];
    });
    Object.keys(customOverviewRegistry).forEach((prog) => {
      delete customOverviewRegistry[prog];
    });
  },
};
