import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import KpiDefinitionData from 'service/KpiDefinitionData';
import Loader from 'components/Loader/Loader';
import KpiDefinitionDrawer from 'components/KpiDefinitionDrawer/KpiDefinitionDrawer';
import { constants } from 'constants/Constants';
import { helperFunctions } from 'utils/helperFunctions';
import * as Styled from './style';
import CardContainer from 'components/redesign/CardContainer';
import { useMetricsCriteria } from '../../../../hooks/useMetricsCriteria';
import { FilterContext } from 'components/FilterBar/FilterProvider';
import { useOperationalMetrics } from 'hooks/operational-metrics-hook';
import { ThemeContext } from '../../../../context/ThemeContext';
import { ScoreCardItemData } from 'components/redesign/ScoreCard/ScoreCardItem/types';
import KpiGridForFour from 'components/redesign/KpiGridForFour';

interface ArchletValueCreationSummaryProps {
  slug: string;
  title: string;
  localFilterData?: any;
}
const ValueCreationScorecard: React.FC<ArchletValueCreationSummaryProps> = ({
  slug,
  title,
  localFilterData,
}) => {
  const [overViewConfig, setOverviewConfig] = useState<ScoreCardItemData[]>([]);
  const {
    getValueAbbreviationParser,
    currencyParser,
    getValueWithUnitAppended,
  } = helperFunctions();
  const {
    archletValueCreationScorecard,
    getArchletValueCreationScorecard,
    getArchletValueCreationScorecardLoading,
  } = useOperationalMetrics();
  const { operationalMetricCriteria } = useMetricsCriteria();
  const { mode } = useContext(ThemeContext);
  const { selectedProduct } = useContext(FilterContext);

  const generateScoreCardItems = (data: any) => {
    return [
      {
        title: constants?.dtvScm?.totalBaselineSpend,
        value: currencyParser(data?.totalBaselineSpend),
        unit: '',
        valueSuffix: null,
        tag:
          data?.totalBaselineSpendPeriodComparison !== null &&
          data?.totalBaselineSpendPeriodComparison !== undefined
            ? {
                isPositiveValue: data?.totalBaselineSpendPeriodComparison >= 0,
                text: currencyParser(data?.totalBaselineSpendPeriodComparison),
                sideLabel: '',
              }
            : null,
        checkPositive: false,
      },
      {
        title: constants?.dtvScm?.valueOpportunityIdentified,
        value: currencyParser(data?.valueOpportunityIdentified),
        unit: '',
        valueSuffix: null,
        tag:
          data?.valueOpportunityIdentifiedPeriodComparison !== null &&
          data?.valueOpportunityIdentifiedPeriodComparison !== undefined
            ? {
                isPositiveValue:
                  data?.valueOpportunityIdentifiedPeriodComparison >= 0,
                text: currencyParser(
                  data?.valueOpportunityIdentifiedPeriodComparison
                ),
                sideLabel: '',
              }
            : null,
        checkPositive: true,
      },
      {
        title: constants?.dtvScm?.totalProjectsCreated,
        value: getValueAbbreviationParser(data?.totalProjectsCreated),
        unit: '',
        valueSuffix: null,
        tag:
          data?.totalProjectsCreatedPeriodComparison !== null &&
          data?.totalProjectsCreatedPeriodComparison !== undefined
            ? {
                isPositiveValue:
                  data?.totalProjectsCreatedPeriodComparison >= 0,
                text: getValueAbbreviationParser(
                  data?.totalProjectsCreatedPeriodComparison
                ),
                sideLabel: '',
              }
            : null,
        checkPositive: true,
      },
      {
        title: constants?.dtvScm?.totalHoursSaved,
        value: getValueWithUnitAppended(data?.totalHoursSaved, ' h'),
        unit: '',
        valueSuffix: null,
        tag:
          data?.totalHoursSavedPeriodComparison !== null &&
          data?.totalHoursSavedPeriodComparison !== undefined
            ? {
                isPositiveValue: data?.totalHoursSavedPeriodComparison >= 0,
                text: getValueWithUnitAppended(
                  data?.totalHoursSavedPeriodComparison,
                  ' h'
                ),
                sideLabel: '',
              }
            : null,
        checkPositive: true,
      },
    ];
  };
  useEffect(() => {
    const procurementCriteria = { ...operationalMetricCriteria };
    (procurementCriteria as any).category =
      localFilterData?.selectedCategory?.value || null;
    (procurementCriteria as any).subCategory =
      localFilterData?.selectedSubCategory?.value || null;
    (procurementCriteria as any).owner =
      localFilterData?.selectedOwner?.value || null;
    getArchletValueCreationScorecard({
      variables: { procurementCriteria },
    });
  }, [
    getArchletValueCreationScorecard,
    operationalMetricCriteria,
    localFilterData,
  ]);

  useEffect(() => {
    if (archletValueCreationScorecard) {
      setOverviewConfig(
        generateScoreCardItems({ ...archletValueCreationScorecard })
      );
    }
  }, [archletValueCreationScorecard]);
  const scorecardKpiDrawer = (
    <KpiDefinitionDrawer
      title={'Summary'}
      KpiDefinitionList={
        KpiDefinitionData[selectedProduct?.productId]?.valueCreation?.summary ??
        []
      }
      data-testid="kpi-definition-drawer"
    />
  );

  return (
    <Styled.ArchletValueCreationSummaryContainer
      data-testid="nrm-useradoption-metrics"
      mode={mode}
      xs={12}
    >
      <Grid container xs={12}>
        {getArchletValueCreationScorecardLoading ? (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        ) : (
          <CardContainer
            slug={slug}
            title={title}
            kpiDefinitionDrawer={scorecardKpiDrawer}
            isDraggable={false}
            isAccordion={false}
          >
            <KpiGridForFour data={overViewConfig} noPadding={true} />
          </CardContainer>
        )}
      </Grid>
    </Styled.ArchletValueCreationSummaryContainer>
  );
};

export default ValueCreationScorecard;
