import React from 'react';
import styled from 'styled-components';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { ScoreCardItemProps } from './types';

const Card = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding-top: 8px;
  padding-right: 8px;
`;

const Title = styled.h2`
  margin: 0;
  ${(props) => props.theme?.mergedTypography?.fontSmallMedium};
  text-align: left;
  color: ${(props) => props.theme?.colors?.textSecondary};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: capitalize;
`;

const Value = styled.h5`
  margin: 0;
  ${(props) => props.theme?.mergedTypography?.fontHeadingH5Bold};
  text-align: left;
  color: ${(props) => props.theme?.colors?.textPrimary};
`;

const SecondTitle = styled.h2`
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 29px;
  height: 40px;
  text-align: left;
  color: ${(props) => props.theme?.colors?.textSecondary};
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

const Subtitle = styled.p`
  margin: 0;
  ${(props) => props.theme?.mergedTypography?.fontXsmallRegular};
  text-align: left;
  color: ${(props) => props.theme?.colors?.textSecondary};
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap; /* Ensure items do not wrap */
  flex-shrink: 0; /* Prevent shrinking */
`;

const Label = styled.span`
  ${(props) => props.theme?.mergedTypography?.fontXsmallRegular};
  text-align: left;
  color: ${(props) => props.theme?.colors?.textSecondary};
`;

const Tag = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  ${(props) => props.theme?.mergedTypography?.fontXsmallRegular};
  flex-wrap: nowrap; /* Ensure items do not wrap */
  background-color: ${(props) => props.backgroundColor};
  color: #3a3a3a;
  flex-shrink: 0; /* Prevent shrinking */
`;

const ArrowIcon = styled.span`
  svg {
    margin-bottom: 2px;
    vertical-align: middle;
  }
`;

const ScoreCardItem: React.FC<ScoreCardItemProps> = ({ data }) => {
  const { title, secondTitle, value, tag, subTitle } = data;

  const tagValue =
    (tag?.showArrow ?? true) ? (
      tag?.isPositiveValue ? (
        <span>
          {tag?.text}{' '}
          <ArrowIcon>
            <MdArrowUpward />
          </ArrowIcon>
        </span>
      ) : (
        <span>
          {tag?.text}{' '}
          <ArrowIcon>
            <MdArrowDownward />
          </ArrowIcon>
        </span>
      )
    ) : (
      tag.text
    );
  return (
    <Card data-testid="score-card-item">
      <Value data-testid="score-card-value">{value}</Value>
      <Title data-testid="score-card-title">{title}</Title>
      {secondTitle && (
        <SecondTitle data-testid="score-card-second-title">
          {secondTitle}
        </SecondTitle>
      )}
      {tag && (
        <TagContainer data-testid="score-card-tag-container">
          <Tag
            backgroundColor={tag.isPositiveValue ? '#B3DACB' : '#F6CCCC'}
            data-testid="score-card-tag"
          >
            {tagValue}
          </Tag>
          <Label data-testid="score-card-tag-label">{tag.sideLabel}</Label>
        </TagContainer>
      )}
      {subTitle && (
        <Subtitle data-testid="score-card-subtitle">{subTitle}</Subtitle>
      )}
    </Card>
  );
};

export default ScoreCardItem;
