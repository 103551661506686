import React from 'react';
import styled from 'styled-components';
import ScoreCardItem from '../ScoreCard/ScoreCardItem';
import { ScoreCardItemData } from '../ScoreCard/ScoreCardItem/types';
import { breakpoints } from 'constants/Constants';

interface CardProps {
  className?: string;
  noPadding?: boolean;
}

const Card = styled.div<CardProps>`
  padding: ${({ noPadding }) => (noPadding ? '0' : '24px')};

  display: grid;
  row-gap: 40px; // Increased gap for separator spacing

  // Mobile layout (2 columns)
  grid-template-columns: repeat(2, 1fr);

  // Tablet layout (3 columns)
  @media (min-width: ${breakpoints?.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  // Desktop layout (3 columns)
  @media (min-width: ${breakpoints?.desktop}) {
    grid-template-columns: repeat(3, 1fr);
  }

  & > * {
    position: relative;
    padding-bottom: 20px;

    &:not(:nth-child(2n + 1)) {
      border-left: ${(props) => `1px solid ${props.theme?.colors?.border}`};
      padding-left: 24px;
    }

    @media (min-width: ${breakpoints?.tablet}) {
      &:not(:nth-child(3n + 1)) {
        border-left: ${(props) => `1px solid ${props.theme?.colors?.border}`};
        padding-left: 24px;
      }

      // Remove border from 4th item in each row
      &:nth-child(3n + 4) {
        border-left: none;
        padding-left: 0;
      }
    }

    @media (min-width: ${breakpoints?.desktop}) {
      &:not(:nth-child(3n + 1)) {
        border-left: ${(props) => `1px solid ${props.theme?.colors?.border}`};
        padding-left: 24px;
      }

      // Remove border from 4th item in each row
      &:nth-child(3n + 4) {
        border-left: none;
        padding-left: 0;
      }
    }
  }
`;

interface KpiGridProps {
  data: Array<ScoreCardItemData>;
  noPadding?: boolean;
}

const KpiGrid: React.FC<KpiGridProps> = ({ data, noPadding = false }) => {
  return (
    <Card noPadding={noPadding}>
      {data.map((item, index) => (
        <ScoreCardItem key={index} data={item} />
      ))}
    </Card>
  );
};

export default KpiGrid;
