import React, { useContext, lazy, useEffect } from 'react';
import { FilterContext } from '../../components/FilterBar/FilterProvider';
import BasicTabs from '../../components/Tab/BasicTabs';
import { useLocation } from 'react-router-dom';
import { constants } from '../../constants/Constants';
import { helperFunctions } from '../../utils/helperFunctions';
import { OverviewService } from '../../service/overviewService';

import GlobalOverview from 'containers/GlobalOverview';
const LazyOverView = lazy(() => import('../../containers/OverView'));
const LazyOrderStatus = lazy(() => import('../../containers/OrderStatus/'));
const LazyValueCreationMetrics = lazy(() => import('../ValueCreationMetrics'));
const LazyBeesVsPepsiConnectMetrics = lazy(
  () => import('../../containers/BeesVsPepsiConnectMetricsContainer')
);
const LazyApplicationPerformance = lazy(
  () => import('../../containers/ApplicationPerformance')
);
const LazyUserBehavior = lazy(
  () => import('../../containers/UserBehaviorEngagement/')
);
const LazyApplicationPerformanceRedesigned = lazy(
  () => import('../../containers/ApplicationPerformanceRedesigned')
);
const Dashboard = React.memo(() => {
  const {
    selectedProduct,
    selectedProgram,
    selectedSector,
    productValues,
    setSelectedProduct,
    selectedModel,
    betaFeatureToggle,
  } = useContext(FilterContext);

  const location = useLocation();
  const { state } = location;
  const { selectedProductId } = state || {};
  let defaultValue = 1;
  if (productValues && productValues.length > 0) {
    defaultValue = productValues[0].productId;
  }
  const productId = selectedProductId || defaultValue;
  const { isDevOrQaOrPreProdEnv } = helperFunctions();
  useEffect(() => {
    if (Array.isArray(productValues) && productValues.length > 0) {
      const filteredProduct = productValues.find(
        (prod) => prod.productId === productId
      );
      setSelectedProduct(filteredProduct || null);
    }
  }, [productValues, setSelectedProduct, productId]);
  const tabArray = [
    { id: 1, label: 'Overview', component: LazyOverView },
    { id: 2, label: 'Order Board', component: LazyOrderStatus },
    { id: 3, label: 'Value Creation', component: LazyValueCreationMetrics },
    {
      id: 4,
      label: 'Bees Vs PepsiConnect Metrics',
      component: LazyBeesVsPepsiConnectMetrics,
    },
    {
      id: 5,
      label: 'App Performance',
      component: LazyApplicationPerformanceRedesigned,
    },
    {
      id: 6,
      label: 'User Adoption',
      component: LazyUserBehavior,
    },
  ];
  const applicationPerformTab = [
    {
      id: 7,
      label: 'App Performance',
      component: LazyApplicationPerformance,
    },
  ];

  function getTabsForProduct(selectedProduct) {
    const productId = selectedProduct?.productId;
    if (productId === constants.products.PepsiConnect) {
      const filteredTabs = tabArray.filter(
        (item) => item.id !== 4 && item.id !== 2
      ); // Remove BEES and Order Board Tab
      return isDevOrQaOrPreProdEnv() || betaFeatureToggle
        ? filteredTabs
        : [tabArray[0]];
    } else if (
      productId === constants.products.Canvas ||
      productId === constants.products.ManufacturingControlTower ||
      productId === constants.products.ShouldCostModelling
    ) {
      return [tabArray[0], tabArray[2], tabArray[4], tabArray[5]];
    } else if (productId === constants.products.R360) {
      const tabs = [tabArray[0], tabArray[2], tabArray[5]];
      if (isDevOrQaOrPreProdEnv()) {
        tabs.splice(2, 0, tabArray[4]);
      }
      return tabs;
    } else if (productId === constants.products.PricingAI) {
      return [tabArray[0], tabArray[2], tabArray[4], tabArray[5]];
    } else if (
      productId === constants.products.GuidedSelling &&
      !selectedModel
    ) {
      return [tabArray[0], tabArray[2], applicationPerformTab[0]];
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.suggestedOrder
    ) {
      return [tabArray[0], tabArray[2], applicationPerformTab[0]];
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.productPairing
    ) {
      return [tabArray[2]];
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.nbA
    ) {
      return [tabArray[2]];
    } else if (
      productId === constants.products.GuidedSelling &&
      selectedModel?.id === constants?.modals?.shareWise
    ) {
      return [tabArray[2]];
    } else if (
      productId === constants.products.E2EWaste ||
      productId === constants.products.MLT
    ) {
      if (betaFeatureToggle) {
        return [tabArray[0], applicationPerformTab[0], tabArray[5]];
      } else {
        return [tabArray[0], applicationPerformTab[0]];
      }
    } else if (productId === constants.products.ShipmentVisibilty) {
      return [tabArray[0], tabArray[2], applicationPerformTab[0]];
    } else if (productId === constants.products.Automation) {
      return [tabArray[0], tabArray[2]];
    } else if (productId === constants.products.PromoAI) {
      return [tabArray[0], tabArray[4], tabArray[5]];
    } else if (productId === constants.products.DTC) {
      return [tabArray[0], tabArray[2], tabArray[4]];
    } else if (productId === constants.products.MenuPro) {
      return [tabArray[0], tabArray[2], tabArray[5]];
    } else if (productId === constants.products.o9Solutions) {
      return [tabArray[0], tabArray[4], tabArray[5]];
    } else if (productId === constants.products.PepsiTastyRewards) {
      return [tabArray[4]];
    } else if (productId === constants.products.GPG) {
      return [tabArray[2], tabArray[4], tabArray[5]];
    } else if (productId === constants.products.MFGPro) {
      return [tabArray[4]];
    } else if (productId === constants.products.Archlet) {
      return [tabArray[2]];
    } else if (productId === constants.products.APP) {
      return [tabArray[5]];
    } else if (productId === constants.products.Kazandirio) {
      return [tabArray[4]];
    } else {
      return tabArray;
    }
  }

  // Step 1: Get the base tabs based on the selected product.
  const baseTabs =
    selectedProduct &&
    selectedProduct.productId !== null &&
    selectedProduct.productId !== undefined
      ? getTabsForProduct(selectedProduct)
      : tabArray.filter((item) => item.id !== 4);

  // Step 2: Check if any tab is a custom Overview (i.e. not GlobalOverview).
  const hasCustomOverview = baseTabs.some(
    (tab) =>
      tab.label.toLowerCase().includes('overview') &&
      tab.component !== GlobalOverview
  );

  // Step 3: Determine if GlobalOverview would render anything.
  // This uses the same conditions as in GlobalOverview:
  // It checks for default scorecards or a custom overview from the registry.
  const overviewFilters = {
    product: selectedProduct?.productId,
    program: selectedProgram?.programId,
  };

  const defaultScoreCards = OverviewService.resolveScoreCards(overviewFilters);
  const defaultScoreCardsExist = defaultScoreCards.length > 0;
  const CustomOverviewComponent =
    OverviewService.resolveCustomOverview(overviewFilters);

  // GlobalOverview should only be added if there is content to display.
  const showGlobalOverview =
    defaultScoreCardsExist || CustomOverviewComponent ? true : false;

  // Step 4: Conditionally add GlobalOverview as the Overview tab
  // if no custom overview already exists and if GlobalOverview would render.
  const selectedTabs =
    !hasCustomOverview && showGlobalOverview
      ? [{ id: 0, label: 'Overview', component: GlobalOverview }, ...baseTabs]
      : baseTabs;

  return (
    <div data-testid="dashboard">
      {selectedProduct && selectedTabs.length > 0 && (
        <BasicTabs
          tabArray={selectedTabs}
          product={selectedProduct}
          model={selectedModel}
          sector={selectedSector}
        />
      )}
    </div>
  );
});

Dashboard.displayName = 'Dashboard';
export default Dashboard;
