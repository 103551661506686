import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { helperFunctions } from '../utils/helperFunctions';
import {
  getDigitalOrdersRegistration,
  getDigitalOrdersSummary,
  getDigitalTotalOrdersCharts,
  getOperationalMetricsOverview,
  getStoreVersusPlan,
  getStoreReachingTarget,
  getDigitizedStores,
  getPepsiConnectScoreCard,
  getSalesUplift,
  getSuggestedOrdersRealTimeTrend,
  getSuggestedOrdersRealTime,
  getNetPromoterScore,
  getRevenueIndicator,
  getAvgSoSizeIndex,
  getTargetPerformanceTunnel,
  getCustomerCallMetrices,
} from '../graphql/queries';

import {
  getDigitalOrderingBehavior,
  getStoreAndUsage,
  getSalesAndDA,
  getCanvasMarkets,
  getCanvasBrands,
  getCanvasOPScoreCard,
  getCanvasScenarios,
  getRegistersByInitiatives,
  getTotalInstallsTrend,
  getTotalRegistersTrend,
  getDTCBusinessMetrics,
  getRegistersMix,
  getPersonaMappingToUsers,
  getAlertsByState,
  getAlertsAssignmentGraph,
  getAlertsBySeverityGraph,
  getMCTVCScoreCard,
  getDtvScmMetrices,
  getBusinessMetricsScoreCard,
  getRestaurantsWithMenuScore,
  getRestaurantsWithBeverages,
  getDTVSCMValueCreationScoreCard,
  getMCTEfficiencyTrend,
  getMCTQualityTrend,
  getMCTUtilityTrend,
  getGSNBATaskResponded,
  getNBAValueCreationFilter,
  getGPGLocalFilters,
  getPaymentAuthRate,
  getRefundRate,
  getGPGTransactionMetrics,
  getGPGVCScorecard,
  getProcessingDaysSavedData,
  getArchletValueCreationScorecardData,
  getArchletLocalFiltersData,
} from '../graphql/operationalQueries';

import { getRetailOpScoreCardMetrics } from '../graphql/retailQueries';

export const useOperationalMetrics = () => {
  const [metricsOverviewData, setMetricsOverviewData] = useState({});
  const [salesAndDAData, setSalesAndDAData] = useState({});
  const [digitalOrdersRegistrationData, setDigitalOrdersRegistrationData] =
    useState({});
  const [digitalOrdersSummaryData, setDigitalOrdersSummaryData] = useState({});
  const [digitalBehaviorData, setDigitalBehaviorData] = useState({});
  const [digitalTotalOrdersChartsData, setDigitalTotalOrdersChartsData] =
    useState({});
  const [storeVsPlanData, setStoreVsPlanData] = useState({});
  const [storeReachingData, setStoreReachingData] = useState({});

  const [storeAndUsageData, setStoreAndUsageData] = useState({});
  const [digitizedStoresData, setDigitizedStoresData] = useState({});
  const [retail360ScoreCardKPI, setRetail360ScoreCardKPI] = useState({});
  const [orderAnalysisScoreCard, setOrderAnalysisScoreCard] = useState({});
  const [customerCallData, setCustomerCallData] = useState({});

  const [salesUpliftData, setSalesUpliftData] = useState({});
  const [suggestedOrderRTData, setSuggestedOrderRTData] = useState({});
  const [suggestedOrderTrendData, setSuggestedOrderTrendData] = useState({});

  const { getMonthFromDate } = helperFunctions();
  const [netPromoterScoreData, setNetPromoterScoreData] = useState({});
  const [revenueIndicatorData, setRevenueIndicatorData] = useState({});
  const [avgSoSizeIndexData, setAvgSoSizeIndexData] = useState({});
  const [targetPerformanceTunnelData, setTargetPerformanceTunnel] = useState(
    {}
  );
  const [canvasMarkets, setCanvasMarkets] = useState({});
  const [canvasBrands, setCanvasBrands] = useState({});
  const [canvasOPScoreCardData, setCanvasOPScoreCardData] = useState({});
  const [canvasScenarios, setCanvasScenarios] = useState(null);
  const [dtcBusinessMetricsData, setDTCBusinessMetricsData] = useState(null);
  const [dtvScmBusinessMetricesData, setDtvScmBusinessMetricesData] =
    useState(null);
  const [registerMixData, setRegisterMixData] = useState(null);

  const [registersByInitiatives, setRegistersByInitiatives] = useState(null);
  const [totalInstallsData, setTotalInstallsData] = useState(null);
  const [totalRegisterData, setTotalRegisterData] = useState(null);
  const [personaUsers, setPersonaUsers] = useState([]);

  const [alertsByStateData, setAlertsByStateData] = useState(null);
  const [alertsAssignmentGraphData, setAlertsAssignmentGraphData] = useState(
    []
  );
  const [alertsBySeverityGraphData, setAlertsBySeverityGraphData] = useState(
    []
  );
  const [mctScoreCardData, setMctScoreCardData] = useState(null);

  const [businessMetricsScoreCardData, setBusinessMetricsScoreCardData] =
    useState(null);
  const [restrauntsWithMenuScoreData, setRestrauntsWithMenuScoreData] =
    useState(null);
  const [restrauntsWithBeveragesData, setRestrauntsWithBeveragesData] =
    useState(null);
  const [dtvScmBusinessMetricsData, setDtvScmBusinessMetricsData] =
    useState(null);
  const [mctUtilityTrendData, setMCTUtilityTrendData] = useState(null);
  const [mctQualityTrendData, setMCTQualityTrendData] = useState(null);
  const [mctEfficiencyTrendData, setMCTEfficiencyTrendData] = useState(null);
  const [taskRespondedData, setTaskResponded] = useState(null);
  const [nbaValueCreationFilterData, setNbaValueCreationFilterData] =
    useState(null);
  const [gpgLocalFiltersData, setGPGLocalFiltersData] = useState(null);
  const [paymentAuthRateData, setPaymentAuthRateData] = useState(null);
  const [refundRateData, setRefundRateData] = useState(null);
  const [gpgVCScoreCardData, setGPGVCScoreCardData] = useState(null);
  const [transactionTrendData, setTransactionTrendData] = useState(null);
  const [averageTransactionValueData, setAverageTransactionValueData] =
    useState(null);
  const [processingDaysSavedData, setProcessingDaysSavedData] = useState(null);
  const [archletValueCreationScorecard, setArchletValueCreationScorecard] =
    useState(null);
  const [archletLocalFilters, setArchletLocalFilters] = useState(null);

  const [getMetricsOverview, { loading: metricsOverviewLoading }] =
    useLazyQuery(getOperationalMetricsOverview, {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getOperationalMetricsOverview: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setMetricsOverviewData(data);
          } else {
            setMetricsOverviewData({});
          }
        }
      },
    });

  const [getDigitalOrdersRegistrationData] = useLazyQuery(
    getDigitalOrdersRegistration,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getDigitalOrdersRegistration: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setDigitalOrdersRegistrationData(data);
          } else {
            setDigitalOrdersRegistrationData({});
          }
        }
      },
    }
  );

  const [getDigitalOrdersSummaryData, { loading: digitalSummaryLoading }] =
    useLazyQuery(getDigitalOrdersSummary, {
      onCompleted: (res) => {
        if (res) {
          const {
            getDigitalOrdersSummary: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setDigitalOrdersSummaryData(data);
          } else {
            setDigitalOrdersSummaryData({});
          }
        }
      },
    });

  const digitalOrdersGraphData = {
    totalDigitalOrders: [],
    digitalNetSale: [],
    month: [],
  };

  function GetMonthName(utcString) {
    const utcDate = new Date(utcString);

    return utcDate.toLocaleString('en-US', {
      month: 'short',
    });
  }

  function GetDigitalNetSalesPercentage(value) {
    return (value * 100).toFixed(2);
  }

  const [
    getDigitalTotalOrdersChartsData,
    { loading: digitalOrdersChartLoading },
  ] = useLazyQuery(getDigitalTotalOrdersCharts, {
    onCompleted: (res) => {
      if (res) {
        const {
          getDigitalTotalOrdersCharts: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          data.forEach((element) => {
            digitalOrdersGraphData.totalDigitalOrders.push(
              element.totalDigitalOrder
            );
            digitalOrdersGraphData.digitalNetSale.push(
              GetDigitalNetSalesPercentage(element.digitalNetSale)
            );
            digitalOrdersGraphData.month.push(GetMonthName(element?.dateTime));
          });
          setDigitalTotalOrdersChartsData(digitalOrdersGraphData);
        } else {
          setDigitalTotalOrdersChartsData({});
        }
      }
    },
  });

  const [getStoreVersusPlanData, { loading: storeVsPlanLoading }] =
    useLazyQuery(getStoreVersusPlan, {
      onCompleted: (res) => {
        if (res) {
          const {
            getStoreVersusPlan: {
              data: storeData,
              responseCode: storeResponseCode,
            },
          } = res;
          if (storeData && storeResponseCode === 200) {
            setStoreVsPlanData(storeData);
          } else {
            setStoreVsPlanData({});
          }
        }
      },
    });

  const [getStoreReachingTargetData, { loading: storeReachingTargetLoading }] =
    useLazyQuery(getStoreReachingTarget, {
      onCompleted: (res) => {
        if (res) {
          const {
            getStoreReachingTarget: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setStoreReachingData(data);
          } else {
            setStoreReachingData({});
          }
        }
      },
    });

  const [getStoreAndUsageData, { loading: storeAndUsageLoading }] =
    useLazyQuery(getStoreAndUsage, {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const { getStoreAndUsage, getStoreAndUsageChart } = res;
          const structuredData = {};
          if (
            getStoreAndUsage?.data &&
            getStoreAndUsage?.responseCode === 200
          ) {
            structuredData.storeEngagedData = getStoreAndUsage?.data;
          }
          if (
            getStoreAndUsageChart?.data &&
            getStoreAndUsageChart?.responseCode === 200
          ) {
            structuredData.storeAndUsageChartData = getStoreAndUsageChart?.data;
          }
          setStoreAndUsageData(structuredData);
        }
      },
    });

  const [
    getDigitalOrderingBehaviorData,
    { loading: digitalOrderingBehaviorLoading },
  ] = useLazyQuery(getDigitalOrderingBehavior, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getAverageNetRevenueStore,
          getAverageOrderStore,
          getAverageOrderStoreSize,
        } = res;
        const structuredData = {};
        if (
          getAverageNetRevenueStore?.data &&
          getAverageNetRevenueStore?.responseCode === 200
        ) {
          structuredData.avgNetRevenueStoreData =
            getAverageNetRevenueStore?.data;
        }
        if (
          getAverageOrderStore?.data &&
          getAverageOrderStore?.responseCode === 200
        ) {
          structuredData.avgOrderStoreData = getAverageOrderStore?.data;
        }
        if (
          getAverageOrderStoreSize?.data &&
          getAverageOrderStoreSize?.responseCode === 200
        ) {
          structuredData.avgOrderStoreSizeData = getAverageOrderStoreSize?.data;
        }
        setDigitalBehaviorData(structuredData);
      }
    },
  });

  const [getDigitizedStoresData] = useLazyQuery(getDigitizedStores, {
    onCompleted: (res) => {
      if (res) {
        const {
          getDigitizedStores: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setDigitizedStoresData(data);
        } else {
          setDigitizedStoresData({});
        }
      }
    },
  });

  const [getSalesAndDAData, { loading: salesAndDALoading }] = useLazyQuery(
    getSalesAndDA,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getSalesAndDALoyaltyTable,
            getSalesAndDA,
            getStoreSalesRevenueImpactDtl,
          } = res;
          const structuredData = {};
          if (
            getSalesAndDALoyaltyTable?.data &&
            getSalesAndDALoyaltyTable?.responseCode === 200
          ) {
            structuredData.salesAndDATableData =
              getSalesAndDALoyaltyTable?.data;
          }
          if (getSalesAndDA?.data && getSalesAndDA?.responseCode === 200) {
            structuredData.salesAndDADataValue = getSalesAndDA?.data;
          }
          if (
            getStoreSalesRevenueImpactDtl?.data &&
            getStoreSalesRevenueImpactDtl?.responseCode === 200
          ) {
            structuredData.storeSalesRevenueImpactDtlData =
              getStoreSalesRevenueImpactDtl?.data;
          }
          setSalesAndDAData(structuredData);
        }
      },
      onError: (err) => {
        console.log('onError: StoreSalesRevenueImpactDtl API', err);
      },
    }
  );
  const [getRetailOpScoreCard, { loading: retailOPScoreCardLoading }] =
    useLazyQuery(getRetailOpScoreCardMetrics, {
      onCompleted: (res) => {
        if (res) {
          const {
            getRetailOPScoreCard: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setRetail360ScoreCardKPI(data);
          } else {
            setRetail360ScoreCardKPI({});
          }
        }
      },
      onError: (err) => {
        console.log('onError: Retail360 Score Card API', err);
      },
    });

  const [getOrderAnalysisScoreCard] = useLazyQuery(getPepsiConnectScoreCard, {
    onCompleted: (res) => {
      if (res) {
        const { getTotalOrderProcessed, getTotalRevenueGenerated } = res;
        const isOrderProcessedSuccess =
          getTotalOrderProcessed?.data &&
          getTotalOrderProcessed?.responseCode === 200;
        const isRevenueGeneratedSuccess =
          getTotalRevenueGenerated?.data &&
          getTotalRevenueGenerated?.responseCode === 200;

        if (isOrderProcessedSuccess || isRevenueGeneratedSuccess) {
          const totalOrderProcessedValue =
            getTotalOrderProcessed?.data?.totalOrderProcessedForLast24Hours;
          const totalRevenueGeneratedValue =
            getTotalRevenueGenerated?.data?.totalRevenueGeneratedForLast24Hours;
          setOrderAnalysisScoreCard({
            totalOrderProcessedValue,
            totalRevenueGeneratedValue,
          });
        }
      }
    },
    onError: (err) => {
      console.log('onError: PepsiConnect Score Card API', err);
    },
  });

  const [getCustomerCallsScoreCard, { loading: customerCallLoading }] =
    useLazyQuery(getCustomerCallMetrices, {
      onCompleted: (res) => {
        if (res) {
          const { getCustomerCalls, getMostSearchedKeywords } = res;
          const structuredData = {};
          const getMonthsFromDate = (dateTime) => {
            const date = new Date(dateTime);
            return date.toLocaleString('en-US', { month: 'short' });
          };

          if (
            getCustomerCalls?.data &&
            getCustomerCalls?.responseCode === 200
          ) {
            structuredData.callVolume = getCustomerCalls?.data?.callVolume;
            structuredData.totalNoOfVisitsKAM =
              getCustomerCalls?.data?.totalNoOfVisitsKAM;
            structuredData.totalNoOfVisitsNAM =
              getCustomerCalls?.data?.totalNoOfVisitsNAM;
            structuredData.customerVisitTrends =
              getCustomerCalls?.data?.customerVisitTrends?.reduce(
                (acc, element) => {
                  acc.createdScenarios.push(element?.totalNoOfVisitsNAM);
                  acc.optimizedScenarios.push(element?.totalNoOfVisitsKAM);
                  acc.month.push(getMonthsFromDate(element?.dateTime));
                  return acc;
                },
                { createdScenarios: [], optimizedScenarios: [], month: [] }
              );

            structuredData.totalNoOfExportsPerPeriod =
              getCustomerCalls?.data?.totalNoOfExportsPerPeriod;
          }

          if (
            getMostSearchedKeywords?.data &&
            getMostSearchedKeywords?.responseCode === 200
          ) {
            structuredData.mostSearchedKeywords = getMostSearchedKeywords?.data;
          }

          setCustomerCallData(structuredData);
        }
      },
      onError: (err) => {
        console.log('onError: R360 Customer Call Score Card API', err);
      },
    });

  const [getSalesUpliftData] = useLazyQuery(getSalesUplift, {
    onCompleted: (res) => {
      if (res) {
        const {
          getSalesUplift: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setSalesUpliftData(data);
        } else {
          setSalesUpliftData({});
        }
      }
    },
    onError: (err) => {
      console.log('getSalesUplift return ', err);
    },
  });

  const [getSOTrendData] = useLazyQuery(getSuggestedOrdersRealTimeTrend, {
    onCompleted: (res) => {
      const ordersTrendData = {
        totalDigitalOrders: [],
        digitalNetSale: [],
        month: [],
      };
      if (res) {
        const {
          getSuggestedOrdersRealTimeTrend: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          data.forEach((element) => {
            ordersTrendData?.totalDigitalOrders.push(
              element?.totalSuggestedOrders
            );
            ordersTrendData.digitalNetSale.push(
              element?.storesPlacingSuggestedOrders
            );
            ordersTrendData.month.push(getMonthFromDate(element?.dateTime));
          });
          setSuggestedOrderTrendData(ordersTrendData);
        } else {
          setSuggestedOrderTrendData({});
        }
      }
    },
  });

  const [getSuggestedOrdersRT] = useLazyQuery(getSuggestedOrdersRealTime, {
    onCompleted: (res) => {
      if (res) {
        const {
          getSuggestedOrdersRealTime: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setSuggestedOrderRTData(data);
        } else {
          setSuggestedOrderRTData({});
        }
      }
    },
    onError: (err) => {
      console.log('Suggested Order Real Time KPI', err);
    },
  });

  const [getNetPromoterScoreData] = useLazyQuery(getNetPromoterScore, {
    onCompleted: (res) => {
      if (res) {
        const {
          getNetPromoterScore: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setNetPromoterScoreData(data);
        } else {
          setNetPromoterScoreData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError: Net Promoter Score', err);
    },
  });

  const [getRevenueIndicatorData] = useLazyQuery(getRevenueIndicator, {
    onCompleted: (res) => {
      if (res) {
        const {
          getRevenueIndicator: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setRevenueIndicatorData(data);
        } else {
          setRevenueIndicatorData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError: Revenue Indicator', err);
    },
  });

  const [getAvgSoSizeIndexData] = useLazyQuery(getAvgSoSizeIndex, {
    onCompleted: (res) => {
      if (res) {
        const {
          getAvgSoSizeIndex: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setAvgSoSizeIndexData(data);
        } else {
          setAvgSoSizeIndexData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError: Avg So Size Index Data', err);
    },
  });

  const [getTargetPerformanceTunnelData] = useLazyQuery(
    getTargetPerformanceTunnel,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getTargetPerformanceTunnel: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setTargetPerformanceTunnel(data);
          } else {
            setTargetPerformanceTunnel({});
          }
        }
      },
      onError: (err) => {
        console.log('onError:Target Performance Funnel Data', err);
      },
    }
  );

  const [getCanvasScenariosData, { loading: canvasScenariosLoading }] =
    useLazyQuery(getCanvasScenarios, {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        const getMonthsFromDate = (dateTime) => {
          const date = new Date(dateTime);
          return date.toLocaleString('en-US', { month: 'short' });
        };

        if (res?.getCanvasScenarios?.responseCode === 200) {
          const data = res.getCanvasScenarios.data;
          const scenariosTrend = data?.trend?.reduce(
            (acc, element) => {
              acc.createdScenarios.push(element?.createdScenarios);
              acc.optimizedScenarios.push(element?.optimizedScenarios);
              acc.month.push(getMonthsFromDate(element?.dateTime));
              return acc;
            },
            { createdScenarios: [], optimizedScenarios: [], month: [] }
          );

          const scenarioData = {
            noOfCreatedScenarios: data?.noOfCreatedScenarios,
            noOfOptimizedScenarios: data?.noOfOptimizedScenarios,
            noOfUsersLvgScenariosPln: data?.noOfUsersLvgScenariosPln,
            scenariosTrend,
          };

          setCanvasScenarios(scenarioData);
        } else {
          setCanvasScenarios([]);
        }
      },
    });

  const [getCanvasMarketsData, { loading: canvasMarketsLoading }] =
    useLazyQuery(getCanvasMarkets, {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getCanvasMarkets: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setCanvasMarkets(data);
          } else {
            setCanvasMarkets([]);
          }
        }
      },
    });

  const [getCanvasBrandsData, { loading: canvasBrandsLoading }] = useLazyQuery(
    getCanvasBrands,
    {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getCanvasBrands: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setCanvasBrands(data);
          } else {
            setCanvasBrands([]);
          }
        }
      },
    }
  );

  const [getCanvasOPScoreCardData, { loading: canvasOPScoreCardLoading }] =
    useLazyQuery(getCanvasOPScoreCard, {
      onCompleted: (res) => {
        if (res) {
          const {
            getCanvasOPScoreCard: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setCanvasOPScoreCardData(data);
          } else {
            setCanvasOPScoreCardData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError: getCanvasOPScoreCardData API', err);
      },
    });

  const [
    getRegistersByInitiativesData,
    { loading: registersByInitiativesLoading },
  ] = useLazyQuery(getRegistersByInitiatives, {
    onCompleted: (res) => {
      if (res) {
        const {
          getRegistersByInitiatives: { data, responseCode },
        } = res;
        const structureData = {
          campaign1: {
            campaignName: '',
            registeredCount: '',
            registeredPercentage: '',
          },
          campaign2: {
            campaignName: '',
            registeredCount: '',
            registeredPercentage: '',
          },
          campaign3: {
            campaignName: '',
            registeredCount: '',
            registeredPercentage: '',
          },
        };

        if (data && data.length > 0 && responseCode === 200) {
          data.forEach((detail, index) => {
            structureData[`campaign${index + 1}`].campaignName =
              detail.campaignName;
            structureData[`campaign${index + 1}`].registeredCount =
              detail.registeredCount;
            structureData[`campaign${index + 1}`].registeredPercentage =
              detail.registeredPercentage;
          });
          setRegistersByInitiatives(structureData);
        } else {
          setRegistersByInitiatives({});
        }
      }
    },
    onError: (err) => {
      console.log('getRegistersByInitiatives error : ', err);
    },
  });

  const [getTotalInstallsTrendData, { loading: totalInstallLoading }] =
    useLazyQuery(getTotalInstallsTrend, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        const getMonthsFromDate = (dateTime) => {
          const date = new Date(dateTime);
          return date.toLocaleString('en-US', { month: 'short' });
        };

        if (res?.getTotalInstallsTrend?.responseCode === 200) {
          const data = res.getTotalInstallsTrend.data;
          const totalInstallTrend = data.installsTrend.reduce(
            (acc, element) => {
              acc.target.push(element.target);
              acc.installs.push(element.installs);
              acc.month.push(getMonthsFromDate(element.date));
              return acc;
            },
            { target: [], installs: [], month: [] }
          );

          const installsData = {
            totalInstalls: data.totalInstalls,
            installsTargetYearly: data.installsTargetYearly,
            installsTrend: {
              target: totalInstallTrend.target,
              createdScenarios: totalInstallTrend.installs,
              month: totalInstallTrend.month,
            },
          };

          setTotalInstallsData(installsData);
        } else {
          setTotalInstallsData(null);
        }
      },
    });

  const [getTotalRegistersTrendData, { loading: totalRegisterLoading }] =
    useLazyQuery(getTotalRegistersTrend, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        const getMonthsFromDate = (dateTime) => {
          const date = new Date(dateTime);
          return date.toLocaleString('en-US', { month: 'short' });
        };

        if (res?.getTotalRegistersTrend?.responseCode === 200) {
          const data = res.getTotalRegistersTrend.data;
          const totalRegisterTrend = data.registerTrend.reduce(
            (acc, element) => {
              acc.registers.push(element.registers);
              acc.month.push(getMonthsFromDate(element.date));
              return acc;
            },
            { registers: [], month: [] }
          );

          const registerData = {
            totalRegisters: data.totalRegisters,
            registersTargetYearly: data.registersTargetYearly,
            registerTrend: {
              createdScenarios: totalRegisterTrend.registers,
              month: totalRegisterTrend.month,
            },
          };
          setTotalRegisterData(registerData);
        } else {
          setTotalRegisterData(null);
        }
      },
    });

  const [getDTCBusinessMetricsData, { loading: dtcBusinessMetricsLoading }] =
    useLazyQuery(getDTCBusinessMetrics, {
      onCompleted: (res) => {
        if (res) {
          const {
            getDTCScoreCard: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setDTCBusinessMetricsData(data);
          } else {
            setDTCBusinessMetricsData({});
          }
        }
      },
      onError: (err) => {
        console.log('DTC Business Metrics Data error : ', err);
      },
    });

  const [
    getDtvScmBusinessMetrices,
    { loading: dtvScmBusinessMetricesLoading },
  ] = useLazyQuery(getDtvScmMetrices, {
    onCompleted: (res) => {
      if (res) {
        const { getGapImproved, getTotalOpportunity } = res;
        const getGapImprovedSuccess =
          getGapImproved?.data && getGapImproved?.responseCode === 200;
        const getTotalOpportunitySuccess =
          getTotalOpportunity?.data &&
          getTotalOpportunity?.responseCode === 200;

        if (getGapImprovedSuccess || getTotalOpportunitySuccess) {
          const getGapImprovedValue = getGapImproved?.data;
          const getTotalOpportunityValue = getTotalOpportunity?.data;
          setDtvScmBusinessMetricesData({
            getGapImprovedValue,
            getTotalOpportunityValue,
          });
        }
      }
    },
    onError: (err) => {
      console.log('onError: DTV SCM Score Card API', err);
    },
  });

  const [getRegistersMixData, { loading: registerMixLoading }] = useLazyQuery(
    getRegistersMix,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getRegistersMix: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setRegisterMixData(data);
          } else {
            setRegisterMixData({});
          }
        }
      },
      onError: (err) => {
        console.log('getRegistersMix error : ', err);
      },
    }
  );

  const [
    getPersonaMappingToUsersData,
    { loading: personaMappingToUsersLoading },
  ] = useLazyQuery(getPersonaMappingToUsers, {
    onCompleted: (res) => {
      if (res) {
        const {
          getPersonaMappingToUsers: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setPersonaUsers(data);
        } else {
          setPersonaUsers([]);
        }
      }
    },
  });

  const [getAlertsByStateData, { loading: alertsByStateLoading }] =
    useLazyQuery(getAlertsByState, {
      onCompleted: (res) => {
        if (res) {
          const {
            getAlertsByState: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setAlertsByStateData(data);
          } else {
            setAlertsByStateData([]);
          }
        }
      },
      onError: (err) => {
        console.log('getAlertsByState error : ', err);
      },
    });

  const [
    getAlertsAssignmentGraphData,
    { loading: alertsAssignmentGraphLoading },
  ] = useLazyQuery(getAlertsAssignmentGraph, {
    onCompleted: (res) => {
      if (res) {
        const {
          getAlertsAssignmentGraph: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setAlertsAssignmentGraphData(data);
        } else {
          setAlertsAssignmentGraphData({});
        }
      }
    },
    onError: (err) => {
      console.log('getAlertsAssignmentGraph error : ', err);
    },
  });

  const [
    getAlertsBySeverityGraphData,
    { loading: alertsBySeverityGraphLoading },
  ] = useLazyQuery(getAlertsBySeverityGraph, {
    onCompleted: (res) => {
      if (res) {
        const {
          getAlertsBySeverityGraph: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setAlertsBySeverityGraphData(data);
        } else {
          setAlertsBySeverityGraphData({});
        }
      }
    },
    onError: (err) => {
      console.log('getAlertsBySeverityGraph error : ', err);
    },
  });

  const [getMCTVCScoreCardData, { loading: mctScoreCardLoading }] =
    useLazyQuery(getMCTVCScoreCard, {
      onCompleted: (res) => {
        if (res) {
          const {
            getMCTVCScoreCard: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setMctScoreCardData(data);
          } else {
            setMctScoreCardData({});
          }
        }
      },
      onError: (err) => {
        console.log('onError: getMCTVCScoreCard API', err);
      },
    });

  const [
    getBusinessMetricsScoreCardData,
    { loading: businessMetricsScoreCardLoading },
  ] = useLazyQuery(getBusinessMetricsScoreCard, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getBusinessMetricsScoreCard: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setBusinessMetricsScoreCardData(data);
        } else {
          setBusinessMetricsScoreCardData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError: getBusinessMetricsScoreCardData API', err);
    },
  });

  const [
    getRestrauntsWithMenuScoreData,
    { loading: restrauntsWithMenuScoreDataLoading },
  ] = useLazyQuery(getRestaurantsWithMenuScore, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getRestaurantsWithMenuScore: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setRestrauntsWithMenuScoreData(data);
        } else {
          setRestrauntsWithMenuScoreData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError: getRestrauntsWithMenuScoreData API', err);
    },
  });

  const [
    getRestrauntsWithBeveragesData,
    { loading: restrauntsWithBeveragesDataLoading },
  ] = useLazyQuery(getRestaurantsWithBeverages, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getRestaurantsWithBeverages: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setRestrauntsWithBeveragesData(data);
        } else {
          setRestrauntsWithBeveragesData({});
        }
      }
    },
    onError: (err) => {
      console.log('onError: getRestrauntsWithBeverages API', err);
    },
  });

  const [
    getDTVSCMValueCreationScoreCardData,
    { loading: dtvScmBusinessMetricsLoading },
  ] = useLazyQuery(getDTVSCMValueCreationScoreCard, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getDTVSCMValueCreationScoreCard: { data, responseCode },
        } = res;

        if (data && responseCode === 200) {
          setDtvScmBusinessMetricsData(data);
        } else {
          setDtvScmBusinessMetricsData({});
        }
      }
    },
    onError: (err) => {
      console.log('dtvScm Business Metrics Data error : ', err);
    },
  });

  const [getArchletLocalFilters, { loading: getArchletLocalFiltersLoading }] =
    useLazyQuery(getArchletLocalFiltersData, {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        if (res) {
          const {
            getArchletLocalFilters: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setArchletLocalFilters(data);
          } else {
            setArchletLocalFilters([]);
          }
        }
      },
      onError: (err) => {
        console.log('archlet local filters error : ', err);
      },
    });

  const [getMCTEfficiencyTrendData, { loading: mctEfficiencyTrendLoading }] =
    useLazyQuery(getMCTEfficiencyTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getMCTEfficiencyTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setMCTEfficiencyTrendData(data);
          } else {
            setMCTEfficiencyTrendData([]);
          }
        }
      },
      onError: (err) => {
        console.log('MCT Efficiency Trend Data error : ', err);
      },
    });

  const [getMCTUtilityTrendData, { loading: mctUtilityTrendLoading }] =
    useLazyQuery(getMCTUtilityTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getMCTUtilityTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setMCTUtilityTrendData(data);
          } else {
            setMCTUtilityTrendData([]);
          }
        }
      },
      onError: (err) => {
        console.log('MCT Utility Trend Data error : ', err);
      },
    });
  const [getMCTQualityTrendData, { loading: mctQualityTrendLoading }] =
    useLazyQuery(getMCTQualityTrend, {
      onCompleted: (res) => {
        if (res) {
          const {
            getMCTQualityTrend: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setMCTQualityTrendData(data);
          } else {
            setMCTQualityTrendData([]);
          }
        }
      },
      onError: (err) => {
        console.log('MCT Quality Trend Data error : ', err);
      },
    });

  const [getGSNBATaskRespondedData, { loading: taskRespondedLoading }] =
    useLazyQuery(getGSNBATaskResponded, {
      onCompleted: (res) => {
        if (res) {
          const {
            getGSNBATaskResponded: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setTaskResponded(data);
          } else {
            setTaskResponded({});
          }
        }
      },
    });

  const [
    getNBAValueCreationFilterData,
    { loading: nbaValueCreationFilterLoading },
  ] = useLazyQuery(getNBAValueCreationFilter, {
    onCompleted: (res) => {
      if (res) {
        const {
          getNBAValueCreationFilter: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setNbaValueCreationFilterData(data);
        } else {
          setNbaValueCreationFilterData({});
        }
      }
    },
  });

  const [getGPGLocalFiltersData, { loading: gpgLocalFiltersLoading }] =
    useLazyQuery(getGPGLocalFilters, {
      fetchPolicy: 'cache-first',
      onCompleted: (res) => {
        if (res) {
          const {
            getGPGLocalFilters: { data, responseCode },
          } = res;
          if (data && responseCode === 200) {
            setGPGLocalFiltersData(data);
          } else {
            setGPGLocalFiltersData({});
          }
        }
      },
    });

  const [getPaymentAuthRateData, { loading: paymentAuthRateLoading }] =
    useLazyQuery(getPaymentAuthRate, {
      onCompleted: (res) => {
        if (res) {
          const {
            getPaymentAuthRate: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setPaymentAuthRateData(data);
          } else {
            setPaymentAuthRateData([]);
          }
        }
      },
      onError: (err) => {
        console.log('getPaymentAuthRate error : ', err);
      },
    });

  const [getRefundRateData, { loading: refundRateLoading }] = useLazyQuery(
    getRefundRate,
    {
      onCompleted: (res) => {
        if (res) {
          const {
            getRefundRate: { data, responseCode },
          } = res;

          if (data && responseCode === 200) {
            setRefundRateData(data);
          } else {
            setRefundRateData([]);
          }
        }
      },
      onError: (err) => {
        console.log('getRefundRate error : ', err);
      },
    }
  );

  const [getTransactionMetricsData, { loading: transactionMetricsLoading }] =
    useLazyQuery(getGPGTransactionMetrics, {
      onCompleted: (res) => {
        if (res) {
          const { getTotalTransactionTrend, getAverageTransactionValue } = res;

          if (
            getTotalTransactionTrend?.data &&
            getTotalTransactionTrend?.responseCode === 200 &&
            getAverageTransactionValue?.data &&
            getAverageTransactionValue?.responseCode === 200
          ) {
            // Process both getTotalTransactionTrend and getAverageTransactionValue data
            setTransactionTrendData(getTotalTransactionTrend.data);
            setAverageTransactionValueData(getAverageTransactionValue.data);
          } else {
            setTransactionTrendData([]);
            setAverageTransactionValueData({});
          }
        }
      },
    });

  const [getGPGVCScorecardData, { loading: gpgVCScoreCardLoading }] =
    useLazyQuery(getGPGVCScorecard, {
      onCompleted: (res) => {
        if (res) {
          const { getGPGVCScorecard } = res;
          if (
            getGPGVCScorecard?.data &&
            getGPGVCScorecard?.responseCode === 200
          ) {
            setGPGVCScoreCardData(getGPGVCScorecard.data);
          } else {
            setGPGVCScoreCardData({});
          }
        }
      },
    });

  const [getProcessingDays, { loading: getProcessingDaysSavedLoading }] =
    useLazyQuery(getProcessingDaysSavedData, {
      onCompleted: (res) => {
        if (res) {
          const { getProcessingDaysSaved } = res;
          if (
            getProcessingDaysSaved?.data &&
            getProcessingDaysSaved?.responseCode === 200
          ) {
            setProcessingDaysSavedData(getProcessingDaysSaved.data);
          } else {
            setProcessingDaysSavedData([]);
          }
        }
      },
    });

  const [
    getArchletValueCreationScorecard,
    { loading: getArchletValueCreationScorecardLoading },
  ] = useLazyQuery(getArchletValueCreationScorecardData, {
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      if (res) {
        const {
          getArchletScoreCard: { data, responseCode },
        } = res;
        if (data && responseCode === 200) {
          setArchletValueCreationScorecard(data);
        } else {
          setArchletValueCreationScorecard({});
        }
      }
    },
  });

  return {
    getMetricsOverview,
    metricsOverviewData,
    metricsOverviewLoading,
    getSalesAndDAData,
    salesAndDAData,
    salesAndDALoading,
    getDigitalOrdersRegistrationData,
    digitalOrdersRegistrationData,
    getDigitalOrdersSummaryData,
    digitalSummaryLoading,
    digitalOrdersSummaryData,
    getDigitalTotalOrdersChartsData,
    digitalTotalOrdersChartsData,
    digitalOrdersChartLoading,
    getStoreVersusPlanData,
    storeVsPlanLoading,
    getStoreVersusPlan,
    storeVsPlanData,
    getStoreAndUsageData,
    storeAndUsageData,
    storeAndUsageLoading,
    getDigitalOrderingBehaviorData,
    digitalOrderingBehaviorLoading,
    digitalBehaviorData,
    storeReachingData,
    getStoreReachingTarget,
    storeReachingTargetLoading,
    getStoreReachingTargetData,
    getDigitizedStoresData,
    digitizedStoresData,
    retail360ScoreCardKPI,
    retailOPScoreCardLoading,
    getRetailOpScoreCard,
    orderAnalysisScoreCard,
    getOrderAnalysisScoreCard,
    salesUpliftData,
    getSalesUpliftData,
    getSOTrendData,
    getSuggestedOrdersRT,
    suggestedOrderRTData,
    suggestedOrderTrendData,
    netPromoterScoreData,
    getNetPromoterScoreData,
    revenueIndicatorData,
    getRevenueIndicatorData,
    avgSoSizeIndexData,
    getAvgSoSizeIndexData,
    targetPerformanceTunnelData,
    getTargetPerformanceTunnelData,
    getCanvasMarketsData,
    canvasMarketsLoading,
    canvasMarkets,
    getCanvasBrandsData,
    canvasBrandsLoading,
    canvasBrands,
    getCanvasOPScoreCardData,
    canvasOPScoreCardLoading,
    canvasOPScoreCardData,
    getCanvasScenariosData,
    canvasScenariosLoading,
    canvasScenarios,
    getRegistersByInitiativesData,
    registersByInitiativesLoading,
    registersByInitiatives,
    getTotalInstallsTrendData,
    totalInstallLoading,
    totalInstallsData,
    getTotalRegistersTrendData,
    totalRegisterLoading,
    totalRegisterData,
    getDTCBusinessMetricsData,
    dtcBusinessMetricsLoading,
    dtcBusinessMetricsData,
    getRegistersMixData,
    registerMixLoading,
    registerMixData,
    getPersonaMappingToUsersData,
    personaMappingToUsersLoading,
    personaUsers,
    getAlertsByStateData,
    alertsByStateLoading,
    alertsByStateData,
    getAlertsAssignmentGraphData,
    alertsAssignmentGraphLoading,
    alertsAssignmentGraphData,
    getAlertsBySeverityGraphData,
    alertsBySeverityGraphLoading,
    alertsBySeverityGraphData,
    mctScoreCardData,
    getMCTVCScoreCardData,
    mctScoreCardLoading,
    getDtvScmBusinessMetrices,
    dtvScmBusinessMetricesData,
    dtvScmBusinessMetricesLoading,
    getCustomerCallsScoreCard,
    customerCallData,
    customerCallLoading,
    getBusinessMetricsScoreCardData,
    businessMetricsScoreCardData,
    businessMetricsScoreCardLoading,
    getRestrauntsWithMenuScoreData,
    restrauntsWithMenuScoreData,
    restrauntsWithMenuScoreDataLoading,
    getRestrauntsWithBeveragesData,
    restrauntsWithBeveragesData,
    restrauntsWithBeveragesDataLoading,
    getDTVSCMValueCreationScoreCardData,
    dtvScmBusinessMetricsLoading,
    dtvScmBusinessMetricsData,
    getMCTEfficiencyTrendData,
    mctEfficiencyTrendLoading,
    mctEfficiencyTrendData,
    getMCTQualityTrendData,
    mctQualityTrendLoading,
    mctQualityTrendData,
    getMCTUtilityTrendData,
    mctUtilityTrendLoading,
    mctUtilityTrendData,
    getGSNBATaskRespondedData,
    taskRespondedData,
    taskRespondedLoading,
    getNBAValueCreationFilterData,
    nbaValueCreationFilterLoading,
    nbaValueCreationFilterData,
    getGPGLocalFiltersData,
    gpgLocalFiltersLoading,
    gpgLocalFiltersData,
    getPaymentAuthRateData,
    paymentAuthRateLoading,
    paymentAuthRateData,
    getRefundRateData,
    refundRateLoading,
    refundRateData,
    getTransactionMetricsData,
    transactionMetricsLoading,
    transactionTrendData,
    averageTransactionValueData,
    getGPGVCScorecardData,
    gpgVCScoreCardLoading,
    gpgVCScoreCardData,
    getProcessingDays,
    getProcessingDaysSavedLoading,
    processingDaysSavedData,
    archletValueCreationScorecard,
    getArchletValueCreationScorecard,
    getArchletValueCreationScorecardLoading,
    archletLocalFilters,
    getArchletLocalFilters,
    getArchletLocalFiltersLoading,
  };
};
