import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: ${(props) => props.theme?.colors?.backgroundLayer1};
  border-radius: 8px;
  padding: 24px;
  box-shadow:
    0 2px 8px -1px rgba(0, 0, 0, 0.1),
    0 4px 12px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const HeaderWrapper = styled.div<{ isAccordion: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ isAccordion }) => (isAccordion ? 'pointer' : 'default')};
`;

export const LeftHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px; /* Adjust spacing as needed */
`;

export const CardHeader = styled.h5`
  color: ${(props) => props.theme?.colors?.textPrimary};
  ${(props) => props.theme?.mergedTypography?.fontHeadingH6Bold};
  margin: 0;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 32px;
    color: ${(props) => props.theme?.colors?.icon};
    transition: transform 0.3s ease;
  }
`;

export const CardContent = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? 'none' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: all 0.3s ease-in-out;
  margin-top: ${(props) => (props.isOpen ? '16px' : '0')};

  @media (max-width: 1024px) {
    gap: 24px;
    flex-direction: column;
    max-height: ${(props) => (props.isOpen ? 'none' : '0')};
  }

  @media (max-width: 768px) {
    gap: 16px;
    max-height: ${(props) => (props.isOpen ? 'none' : '0')};
  }
`;

export const ChildWrapper = styled.div`
  flex: 1;
  min-width: 0;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
