import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import KpiDefinitionData from 'service/KpiDefinitionData';
import Loader from 'components/Loader/Loader';
import KpiDefinitionDrawer from 'components/KpiDefinitionDrawer/KpiDefinitionDrawer';
import { helperFunctions } from 'utils/helperFunctions';
import * as Styled from './style';
import { ThemeContext } from '../../../../context/ThemeContext';
import { useOverview } from '../../../../hooks/overview-hook';
import { useMetricsCriteria } from '../../../../hooks/useMetricsCriteria';
import { FilterContext } from '../../../../components/FilterBar/FilterProvider';
import CardContainer from '../../../../components/redesign/CardContainer';
import { ScoreCardItemData } from '../../../../components/redesign/ScoreCard/ScoreCardItem/types';
import KpiGrid from '../../../../components/redesign/KpiGrid';

interface ValueCreationNrmProps {
  slug: string;
  title: string;
}
const SummaryMetricsRgmApp: React.FC<ValueCreationNrmProps> = ({
  slug,
  title,
}) => {
  const {
    getValueAbbreviationParser,
    getNAForPercentageValue,
    percentageParser,
  } = helperFunctions();
  const [overViewConfig, setOverviewConfig] = useState<ScoreCardItemData[]>([]);
  const {
    rgmAppUserAdoptionScorecard,
    getRgmAppUserAdoption,
    getRgmAppUserAdoptionLoading,
  } = useOverview() as any;
  const { operationalMetricCriteria } = useMetricsCriteria();
  const { mode } = useContext(ThemeContext);
  const { selectedProduct } = useContext(FilterContext);

  const generateScoreCardItems = (data: any) => {
    return [
      {
        title: 'Total Users',
        value: getValueAbbreviationParser(data?.totalUser),
        unit: '',
        valueSuffix: null,
        tag: null,
        checkPositive: true,
      },
      {
        title: 'Active Users',
        value: getValueAbbreviationParser(data?.activeUser),
        unit: '',
        valueSuffix: null,
        tag:
          data?.activeUsersPercentageDelta !== null
            ? {
                isPositiveValue: data?.activeUsersPercentageDelta >= 0,
                text: getNAForPercentageValue(data?.activeUsersPercentageDelta),
                sideLabel: '',
              }
            : null,
        checkPositive: true,
      },
      {
        title: 'Adoption Rate',
        value: percentageParser(data?.adoptionRatePercentage),
        unit: '',
        valueSuffix: null,
        tag:
          data?.adoptionRatePercentageDelta !== null
            ? {
                isPositiveValue: data?.adoptionRatePercentageDelta >= 0,
                text: getNAForPercentageValue(
                  data?.adoptionRatePercentageDelta
                ),
                sideLabel: '',
              }
            : null,
        checkPositive: true,
      },
    ];
  };
  useEffect(() => {
    const overviewMetricCriteria = { ...operationalMetricCriteria };
    getRgmAppUserAdoption({
      variables: { overviewMetricCriteria },
    });
  }, [getRgmAppUserAdoption, operationalMetricCriteria]);

  useEffect(() => {
    if (rgmAppUserAdoptionScorecard) {
      setOverviewConfig(
        generateScoreCardItems({ ...rgmAppUserAdoptionScorecard })
      );
    }
  }, [rgmAppUserAdoptionScorecard]);
  const scorecardKpiDrawer = (
    <KpiDefinitionDrawer
      title={'Summary'}
      KpiDefinitionList={
        KpiDefinitionData[selectedProduct?.productId]?.userBehaviorAndEngagement
          ?.userAdoption ?? []
      }
      data-testid="kpi-definition-drawer"
    />
  );

  return (
    <Styled.RgmAppUserAdoptionContainer
      data-testid="nrm-useradoption-metrics"
      mode={mode}
      xs={12}
    >
      <Grid container xs={12}>
        {/* {!showInfoIcon && (
          <Grid item xs={12} data-testid="rgmapp-overview-header">
            <Typography className="cardHeader valueCreationCardHeader">
              {
                constants?.overview
                  ?.userAdoption
              }
              <KpiDefinitionDrawer
                title=""
                KpiDefinitionList={
                  KpiDefinitionData[selectedProduct?.productId]
                    ?.userBehaviorAndEngagement?.userAdoption ?? []
                }
              />
            </Typography>
          </Grid>
        )} */}

        {getRgmAppUserAdoptionLoading ? (
          <Grid item xs={12}>
            <Loader />
          </Grid>
        ) : (
          <CardContainer
            slug={slug}
            title={title}
            kpiDefinitionDrawer={scorecardKpiDrawer}
            isDraggable={false}
            isAccordion={false}
          >
            <KpiGrid data={overViewConfig} noPadding={true} />
          </CardContainer>
        )}
      </Grid>
    </Styled.RgmAppUserAdoptionContainer>
  );
};

export default SummaryMetricsRgmApp;
