import React, { useContext } from 'react';
import * as Styled from './style';
import { Divider, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import VerticalDividerCard from '../VerticalDividerCard';
import { helperFunctions } from '../../utils/helperFunctions';
import useCustomMediaQuery from '../../utils/useCustomMediaQuery';
import { screenSizes } from '../../constants/Constants';
import { ThemeContext } from '../../context/ThemeContext';

const IncidentMonitoringHeader = ({ incidentMonitoringOverviewData }) => {
  const { getNAForInvalidValue, formatTimeWithSeconds } = helperFunctions();
  const { mode } = useContext(ThemeContext);
  const isMobile = useCustomMediaQuery(screenSizes.mobile);
  const isTablet = useCustomMediaQuery(screenSizes.tablet);

  const RenderItem = ({ value, unit }) => {
    let calculatedValue = getNAForInvalidValue(value);
    let alignmentValues = '';

    if (isMobile || isTablet) {
      alignmentValues = 'center';
    }

    if (calculatedValue !== 'NA') {
      if (calculatedValue == 0.0 || calculatedValue == 0.0) {
        calculatedValue = 0;
      }
    } else {
      unit = '';
    }

    return (
      <Stack
        direction="row"
        alignItems={alignmentValues}
        justifyContent={alignmentValues}
        width="100%"
        sx={{ height: isMobile || isTablet ? '40px' : undefined }}
      >
        <Styled.ValueText mode={mode}>{calculatedValue}</Styled.ValueText>
        {unit && <Styled.UnitText mode={mode}>{unit}</Styled.UnitText>}
      </Stack>
    );
  };

  const RenderTime = ({ value, customWidth }) => {
    let alignmentValues = '';

    if (isMobile || isTablet) {
      alignmentValues = 'center';
    }

    return (
      <Stack
        direction="row"
        alignItems={alignmentValues}
        justifyContent={alignmentValues}
        width={customWidth || '100%'}
        sx={{ height: isMobile || isTablet ? '40px' : undefined }}
      >
        <Styled.ValueText mode={mode}>{value}</Styled.ValueText>
      </Stack>
    );
  };

  function getMarginTop(title) {
    if (isMobile) {
      if (title == 'INCIDENT COUNT') {
        return '-7px';
      } else {
        return '0px';
      }
    }
  }

  function getMarginLeft(title) {
    if (isMobile) {
      if (title != 'INCIDENT COUNT') {
        return '-8px';
      } else {
        return '0px';
      }
    }
  }

  const RenderDescriptionText = ({ title, subTitle, customWidth }) => {
    return (
      <div
        style={{
          width: customWidth || '130%',
          display: !isMobile ? 'flex' : 'block',
          alignItems: 'center',
          marginTop: isMobile ? '4px' : ' ',
        }}
      >
        <Styled.RenderItemText
          mode={mode}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: getMarginTop(title),
            marginLeft: getMarginLeft(title),
          }}
        >
          {title}
        </Styled.RenderItemText>
        {!isMobile && <div style={{ width: '2%' }} />}
        <Styled.RenderItemText
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: isMobile ? '-10px' : '0px',
          }}
          mode={mode}
        >
          {subTitle}
        </Styled.RenderItemText>
      </div>
    );
  };

  return (
    <Styled.MainContainer
      data-testid="incident-header-mainContainer"
      mode={mode}
    >
      <Styled.InnerContainer
        container
        data-testid="incident-header-innerContainer"
        mode={mode}
      >
        <Grid
          item
          data-testid="incident-header-incidentCount"
          md={3}
          sm={3}
          xs={5}
          sx={{
            paddingRight: isMobile ? '10px' : '0px',
            display: 'grid ',
            justifyContent: 'center',
          }}
        >
          {IncidentCountMetrics()}
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className="firstVerticalBarContainer"
        />
        <Grid
          item
          data-testid="incident-header-respondTime"
          md={3}
          sm={3}
          xs={5}
          sx={{
            paddingRight: isMobile ? 4 : 0,
            display: 'grid',
            justifyContent: 'center',
          }}
        >
          {ResponsTimeMetrics()}
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className="secondVerticalBarContainer"
        />
        <Grid
          item
          data-testid="incident-header-resolutionTime"
          md={3}
          sm={3}
          xs={12}
          sx={{
            display: 'grid ',
            justifyContent: 'center',
            marginTop: isMobile ? '15px' : '0px',
          }}
        >
          {ResolutionTimeMetrics()}
        </Grid>
      </Styled.InnerContainer>
    </Styled.MainContainer>
  );

  function ResolutionTimeMetrics() {
    return (
      <VerticalDividerCard
        numbers={
          <RenderTime
            value={formatTimeWithSeconds(
              incidentMonitoringOverviewData?.resolutionTime
            )}
          />
        }
        description={
          <RenderDescriptionText title="MEAN TIME TO" subTitle="RESOLVE" />
        }
      />
    );
  }

  function ResponsTimeMetrics() {
    return (
      <VerticalDividerCard
        numbers={
          <RenderTime
            value={formatTimeWithSeconds(
              incidentMonitoringOverviewData?.responseTime
            )}
            customWidth={isMobile ? '160%' : '100%'}
          />
        }
        description={
          <RenderDescriptionText
            title="MEAN TIME TO"
            subTitle="RESPOND"
            customWidth={isMobile ? '178%' : '130%'}
          />
        }
      />
    );
  }

  function IncidentCountMetrics() {
    return (
      <VerticalDividerCard
        numbers={
          <RenderItem
            value={incidentMonitoringOverviewData?.incidentCount}
            unit=""
          />
        }
        description={
          <RenderDescriptionText title="INCIDENT COUNT" subTitle="" />
        }
      />
    );
  }
};

export default IncidentMonitoringHeader;
